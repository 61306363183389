import { useDispatch, useSelector } from 'react-redux';
import { Hotline } from 'react-leaflet-hotline';

import { useEffect, useState, useRef, useMemo } from 'react'
import React from 'react';
import { Badge } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import { MapContainer, TileLayer, useMap, Marker, Circle, useMapEvents, FeatureGroup } from 'react-leaflet';


import { setMarkers, updateMarker, nextImage } from '../redux/mapSlice';
import { FiMapPin } from 'react-icons/fi';
import { useGetGameMetaDataQuery, useUpdateStreamMutation, useGetThemesQuery, useGetProfileQuery } from '../redux/apiSlice';

import 'leaflet/dist/leaflet.css';

import L, { bounds } from 'leaflet';
import * as ReactDOMServer from 'react-dom/server';
const zip = (a, b) => a.map((k, i) => [k, b[i]]);


const Markers = ({ stream }) => {

  const CustomMarker = ({ marker, markerPrev, id, onMarkerClick }) => <Marker icon={L.divIcon({

    className: "custom-icon", html: ReactDOMServer.renderToString(

      <div className={`custom-marker-container`}>

        <FiMapPin style={{
          ...('right' in marker ? (marker.right ?
            (marker.right === markerPrev?.right ? { color: '#699f4c' } :
              { animation: 'change-color-to-green 0.5s forwards' }) :
            (marker.right === markerPrev?.right ? { color: '#FF5555' } :
              { animation: 'change-color-to-red 0.5s forwards' })) :

            { color: 'black' }), ...(marker?.hide ? { opacity: 0 } : { opacity: 1 })
        }} size={42} />

        {
          marker.message && <div style={{ animation: 'change-opacity-to-one 0.5s forwards' }}>

            <Badge color="primary" className='fs-6'>
              {marker.message}
            </Badge>

          </div>

        }

        {

          marker.badge != null ?

            <div style={markerPrev?.badge !== marker.badge ? { animation: 'change-opacity-to-one 0.5s forwards' } : {}}>

              <Badge color="primary" className='fs-6'>
                фото {marker.imgIdx + 1}
              </Badge>


            </div>
            :
            markerPrev?.badge != null &&
            <div style={{ animation: 'change-opacity-to-zero 0.5s forwards' }} >
              <Badge color="primary" className='fs-6'>
                фото {markerPrev.imgIdx + 1}
              </Badge>
            </div>



        }


      </div>


    )
  })}
    position={marker.coords} eventHandlers={{ click: e => { onMarkerClick(id) } }} >

  </Marker>

  const dispatch = useDispatch();

  const markers = useSelector(state => stream ? state.mapStream.markers : state.map.markers)
  const markersPrev = useSelector(state => stream ? state.mapStream.markersPrev : state.map.markersPrev)

  const onMarkerClick = (id) => {

    dispatch(updateMarker(id));
    dispatch(nextImage())

  }

  return markers && zip(markers, markersPrev).map(([marker, markerPrev], id) =>

    marker?.coords && <CustomMarker marker={marker} markerPrev={markerPrev} id={id} onMarkerClick={onMarkerClick} />
  )
}
const Arrows = ({ coordinates }) => {

  const c0 = { r: 0, g: 0, b: 153, t: 0 }
  const c1 = { r: 0, g: 0, b: 255, t: 0.20 }
  const c2 = { r: 0, g: 128, b: 255, t: 0.40 }
  const c3 = { r: 255, g: 255, b: 102, t: 0.60 }
  const c4 = { r: 255, g: 128, b: 0, t: 0.80 }
  const c5 = { r: 255, g: 0, b: 0, t: 1 }

  const palette = [c0, c1, c2, c3, c4, c5]
  //const g = [{lat:-60,lng:50,value:0},{lat:-70,lng:60,value:1}]

  return <Hotline //pairs.map(([from, to]) => (
    data={coordinates}
    getLat={t => t.lat}
    getLng={t => t.lng}
    getVal={t => t.value}
    options={{ palette }}
  />;

}
const Map = () => {

  const [mapContainerDOM, setMapContainerDom] = useState();

  const {
    data: settings, isSuccess: settingsLoaded, isFetching: settingsFetching
  } = useGetProfileQuery({ data_type: 'settings' }); //,{refetchOnMountOrArgChange: true,}



  const [mapObj, setMapObj] = useState(null);

  const [stream, setStream] = useState(null);
  const [game, setGame] = useState(null);

  const markerGroupRef = useRef();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('stream') && !location.pathname.includes('streaming_list')) { setStream(true); setGame(false) }
    else if (location.pathname.includes('game')) { setGame(true); setStream(false) }
    else { setStream(false); setGame(false) }
  }, [location]
  )

  const { data: { isActive: isActiveGame, mode: currentGameMode } = { isActive: undefined, mode: undefined } } = useGetGameMetaDataQuery();

  useEffect(() => {
    setMapContainerDom(document.getElementById("map-resizable-container"))

  }, [])

  const {
    data: themeData, isSuccess: themeLoaded, isFetching: themeDataFetching
  } = useGetThemesQuery(); //,{refetchOnMountOrArgChange: true,}

  const dispatch = useDispatch();

  const mapState = useSelector(state => stream ? state.mapStream : state.map)

  const fitBounds = (bounds) => {
    /*if ( (mapState.circles != null) && (mapState.circles.length !== 0 ) ) {
      
      let bounds = circleGroupRef.current.getBounds()
      if (bounds.isValid()) mapObj.target.fitBounds(bounds)

    } */
    //else if (mapObj && markerGroupRef.current) {

    if (bounds) {

      mapObj.target.fitBounds(bounds)

    }

    else if (markerGroupRef.current) {
      let bounds = markerGroupRef.current.getBounds()

      if (bounds.isValid()) mapObj.target.fitBounds(bounds)
    }
  }

  const blurMap = () => {
    if (mapContainerDOM) {
      mapContainerDOM.children[0].classList.add('disappear'); mapContainerDOM.children[0].classList.remove('appear')
    }
  }
  const unblurMap = () => {
    if (mapContainerDOM) {
      mapContainerDOM.children[0].classList.add('appear'); mapContainerDOM.children[0].classList.remove('disappear')
    }
  }

  useEffect(() => {

    if (mapState.fetching) blurMap();
    if (!mapState.fetching) { fitBounds(); unblurMap() }

  }, [mapState.fetching])


  const MapAutoResizer = () => {

    const map = useMap();

    const resizeObserver = new ResizeObserver(() => {
      map.invalidateSize();
    });
    if (mapContainerDOM) resizeObserver.observe(mapContainerDOM);

  }

  const [updateStream, { }] = useUpdateStreamMutation();

  useEffect(() => {
    if (game && settings?.settings_streaming.value) updateStream({ markers: mapState.markers, markersPrev: mapState.markersPrev, marker_history: mapState.marker_history })
    else if (stream) fitBounds(mapState.marker_history && mapState.marker_history.length !== 0 ? mapState.marker_history : null) ////////////////////
    //////////////////////////////////////////
  }, [mapState.markers, mapState.markersPrev, mapState.marker_history])


  const LocationMarker = () => {

    const map = useMapEvents({

      click(e) {

        if (currentGameMode === 'arbitrary' || currentGameMode === 'warmer') {
          dispatch(setMarkers({ markers: [{ id: 0, badge: 0, imgIdx: mapState.currentImage, coords: [e.latlng.lat, e.latlng.lng] }] }));

          if (game && settings.settings_streaming.value) updateStream({ markers: mapState.markers, markersPrev: mapState.markersPrev, marker_history: mapState.marker_history })

        }

        //if (!settings.settings_show_send_button.value) { setTimeout(() => document.getElementById("send-button").click(), 0) }
        //map.flyTo([e.latlng.lat % 90,e.latlng.lng % 180], map.getZoom())
      },

    })

  }


  const markersMemo = useMemo(() => <Markers />, [])
  const markersStreamMemo = useMemo(() => <Markers stream={true} />, [])

  useEffect(() => {
    if (!isActiveGame) fitBounds()
    //fitBounds();

  }, [isActiveGame])

  //noWrap={true}
  //maxBounds={[[-90, -180],[90, 180]]} 
  ////////////////////////////////////////////////////////////////////////////////////////

  //center={[51.505, -0.09]}
  //[[-90, -Infinity], [90, Infinity]]

  //      <MapContainer whenReady={setMapObj} maxBounds={  [[-90, -Infinity], [90, Infinity]] } maxBoundsViscosity={1} center={  [51.505, -0.09] } zoom={ 2 } minZoom={ 1} maxZoom={ 7} scrollWheelZoom={true}>
  
  const tileLayerMemo = useMemo(() => <TileLayer

  url={`${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/django_static/static/tiles/{z}_{x}_{y}.png`}
/>, []); // Wrap Map component with useMemo


  if (settingsLoaded && themeLoaded && !settingsFetching && !themeDataFetching) {
    
    if (themeData.currentTheme && settings?.settings_theme_mode_on.value && !stream) {
      return (

        <MapContainer whenReady={setMapObj} maxBounds={themeData.currentTheme.bounds } maxBoundsViscosity={1} center={themeData.currentTheme.center} zoom={ themeData.currentTheme.z_min} minZoom={themeData.currentTheme.z_min} maxZoom={themeData.currentTheme.z_max} scrollWheelZoom={true}>

          <LocationMarker />

          <TileLayer

            url={`${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/django_static/static/${'tiles_' + themeData.currentTheme.title_en }/{z}_{x}_{y}.png`}
          
          />

          {mapState.marker_history && mapState.marker_history.length && <Arrows coordinates={mapState.marker_history} />}
          <MapAutoResizer />

          <FeatureGroup ref={markerGroupRef}>

            {
              stream ? markersStreamMemo : markersMemo
            }

            {
              mapState.circles && mapState.circles.map(circle =>
                <Circle
                  center={circle.center}
                  fillColor="blue"
                  radius={circle.radius} />
              )
            }
          </FeatureGroup>

        </MapContainer>

      )
    } else return (

        <MapContainer whenReady={setMapObj} maxBounds={[[-90, -Infinity], [90, Infinity]]} maxBoundsViscosity={1} center={ [51.505, -0.09]} zoom={2} minZoom={1} maxZoom={7} scrollWheelZoom={true}>

          <LocationMarker />

          {tileLayerMemo}
          
          {mapState.marker_history && mapState.marker_history.length && <Arrows coordinates={mapState.marker_history} />}
          <MapAutoResizer />

          <FeatureGroup ref={markerGroupRef}>

            {
              stream ? markersStreamMemo : markersMemo
            }

            {
              mapState.circles && mapState.circles.map(circle =>
                <Circle
                  center={circle.center}
                  fillColor="blue"
                  radius={circle.radius} />
              )
            }
          </FeatureGroup>

        </MapContainer>

      )
  }
}


export default Map
