import { Button, Progress, Modal, ModalBody, ModalHeader, FormGroup, Input, Label } from 'reactstrap';
import { useGetProfileQuery, useSetSettingsMutation } from '../../redux/apiSlice';
import styled, { keyframes, css } from 'styled-components';
import { useState } from 'react';
import { useEffect } from 'react';
import NewAchievementNotice from '../NewAchievementNotice';
import Chat from './Chat';

const growAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const GreenCircle = styled.div`

    position: absolute;
    right:0;
    margin-right:1em;

    width: 3em;
    height: 3em;
    background-color: green;
    border-radius: 50%;
    opacity:0.7;

    display:flex;
    justify-content:center;
    align-items:center;
    
    animation: ${growAnimation} 1s ease-in-out; 

`;

const MainComponent = ({ stream, LeftColumn, RightColumn }) => {

    
    const [checked,setChecked] = useState(false)
    useEffect( ()=> {
        if(checked) {
            setSettings({ settings_show_progress_each_time: false }).then( ()=>{setShowAchievements(showAchievements => !showAchievements); refetch() } )
        }
    } , [checked] )

    const { data: achievements = [], refetch } = useGetProfileQuery({ data_type: 'achievements_info' });

    const [setSettings, { }] = useSetSettingsMutation();
    const { data: settings, isFetching: settingsFetching, isSuccess: settingsLoaded } = useGetProfileQuery({ data_type: 'settings' });

    const [showUpdatedProgress, setShowUpdatedProgress] = useState(false);
    const [showAchievements, setShowAchievements] = useState(false);
    const [showGreenCircles, setShowGreenCircles] = useState(false);
    const [amount, setAmount] = useState(null)
    const [newAchievements, setNewAchievements] = useState(false);

    useEffect(() => {

        if (achievements.filter(achievement => achievement.obtained && achievement.updated).length) setNewAchievements(true)

        if (achievements.filter(achievement => achievement.updated && !achievement.obtained).length) {
            setShowAchievements(true)
        }

        let newAmount = achievements.filter(achievement => achievement.obtained && achievement.updated).length;
        if (newAmount) setAmount(amount => amount + newAmount)



    }, [achievements])

    useEffect(() => {

        if (showAchievements) {
            setTimeout(() => {
                setShowGreenCircles(true);
                setShowUpdatedProgress(true);
            }, 500);
        }
        if (!showAchievements) { setShowUpdatedProgress(false); setShowGreenCircles(false) }


    }, [showAchievements])

    return (


        <>
            <div class='d-flex flex-column flex-lg-row position-relative ' >

                <div class='flex-grow-1'>
                    {LeftColumn}
                </div>

                <div className='mh-100 flex-shrink-0 shadow-lg ' style={{ flexBasis: '20em', background: 'ghostwhite' }}>
                    {RightColumn}

                    {settings?.settings_streaming?.value || stream ? <Chat /> : null}

                </div>

            </div>



            <Modal size='xl' centered={true} isOpen={showAchievements} toggle={() => { setShowAchievements(showAchievements => !showAchievements); refetch() }}>
                <ModalHeader toggle={() => { setShowAchievements(showAchievements => !showAchievements); refetch() }}>Достижения</ModalHeader>
                <ModalBody>
                    {
                        achievements.filter(achievement => achievement.updated && !achievement.obtained).map(achievement =>
                            <div className='mt-3 mb-3 text-center'>

                                {showGreenCircles && <GreenCircle><span className='fs-3 fw-bold text-white'>+{achievement.diff}</span></GreenCircle>}

                                <span className='fs-3 fw-bold'>{achievement.name}</span>
                                <p>{achievement.goal}</p>

                                < Progress
                                    style={{
                                        height: '3em',
                                    }}

                                    color="success"
                                    value={showUpdatedProgress ? achievement.value / achievement.threshold * 100 : (achievement.value - achievement.diff) / achievement.threshold * 100}
                                >

                                </Progress>
                                {achievement.result ? <p>вы угадали: <b>{achievement.result}</b> </p> : null}
                            </div>
                        )
                    }

                    <FormGroup
                        check

                        onClick={() => setChecked(checked => !checked)}
                    >
                        <Input checked={checked} type="checkbox" />
                        <Label style={{ cursor: 'pointer' }} check>
                            Не показывать прогресс достижений после игр
                        </Label>
                    </FormGroup>
                </ModalBody>

            </Modal>

            {
                newAchievements &&
                <NewAchievementNotice setNewAchievements={setNewAchievements} amount={amount} />
            }
        </>
    )
}

export default MainComponent