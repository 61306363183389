
import './index.css';
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Header from './components/Header';

import Game from './components/Game/Game';
import GameFast from './components/Game/GameFast';
import Profile from './components/Profile/MainComponent';
import About from './components/About';

import { useEffect, useState } from 'react';
import ErrorPage from './components/ErrorPage';
import { Outlet } from 'react-router-dom';
import BottomBar from './components/BottomBar';
import {
  useLocation,

} from "react-router-dom";
import { useGetGameMetaDataQuery, useLazyGetProfileQuery, useGetProfileQuery, useCheckIfLoggedQuery, useStartGameMutation } from './redux/apiSlice';
import BackToGame from './components/BackToGame';
import StreamingList from './components/StreamingList';
import Stream from './components/Game/Stream';
import ThemeModePage from './components/ThemeModePage';

import Achievements from './components/Profile/Achievements';
import History from './components/Profile/History';
import Liked from './components/Profile/Liked';
import Stats from './components/Profile/Stats';
import Settings from './components/Profile/Settings';

import { setHasProfile } from './redux/mapSlice';
import { useSelector,  useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import Maintenance from './components/Maintenance';

const AppLayout = () => (
  <>
    <div style={{ flexGrow: 1 }}>

      <Header />

      <Outlet />

    </div>

    <BottomBar />

  </>
);

const AppContent = ({ firstVisit }) => {

  const { data: { mode: currentGameMode } = { isActive: undefined, mode: undefined } } = useGetGameMetaDataQuery();
  const location = useLocation();
  return (

    <>
      <BackToGame />
      <Routes location={location} >

        <Route element={<AppLayout />} >

          <Route path="/about" element={<About />} />
          <Route path="/game" element={currentGameMode === 'fast' ? <GameFast /> : <Game />} />
          <Route path="/modes" element={<About />} />
          <Route path="/profile/:userId?" element={<Profile />}>

            <Route index element={<Navigate to="stats" replace />} />
            <Route path="settings" element={<Settings />} />
            <Route path="liked" element={<Liked />} />
            <Route path="stats" element={<Stats />} />
            <Route path="history" element={<History />} />
            <Route path="achievements" element={<Achievements />} />

            <Route path="*" element={<Stats />} />

          </Route>
          <Route path="/stream/:userId" element={<Stream />} />
          <Route path="/theme" element={<ThemeModePage />} />
          <Route path="/streaming_list" element={<StreamingList />} />
          <Route path="/" element={<About firstVisit={firstVisit} />} />
        </Route>
        <Route path="/error" element={<ErrorPage />} />


      </Routes>
    </>

  )

}

const App = () => {

  //const [trigger, result, lastPromiseInfo] = useLazyGetProfileQuery();

  const [getProfile] = useLazyGetProfileQuery({user_id:null, data_type:null});
  const [getCoins] = useLazyGetProfileQuery({user_id:null,data_type:'coins'});
  
  const hasProfile = useSelector(state => state.map.hasProfile)
  const dispatch = useDispatch();

  const [startGame, { }] = useStartGameMutation();

  const [firstVisit, setFirstVisit] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
 
  useEffect(() => {
    
    getProfile().then((res) => {
      
      getCoins();
      dispatch(setHasProfile(true));
    
      if (res.first_visit) {
        setFirstVisit(true); startGame().then(() => setGameStarted(true))
      }
      else {
        setFirstVisit(false)
      }
    })

  }, [hasProfile])
  if (!!+process.env.REACT_APP_MAINTENANCE ) return <Maintenance />
  if (!hasProfile) return <div className='w-100 h-100 d-flex align-items-center justify-content-center'> <Spinner /></div>
  else if (firstVisit ? hasProfile && gameStarted : hasProfile) return <AppContent firstVisit={firstVisit} />
}

export default App;
