
import { useGetProfileQuery } from '../redux/apiSlice'
import { Tooltip } from 'reactstrap'
import { useState } from 'react'

import HintIcon from './hint_icon.svg'
import ScoreIcon from './score_icon.svg'
import ThemeIcon from './theme_icon.svg'

const Points = ({ bg }) => {

    const {
        data: pointsData,
        isSuccess
    } = useGetProfileQuery({data_type:'coins'});

    if (isSuccess) return (
        <div >
                        
            <div style={{ backgroundColor:  bg }} className='rounded-4 ms-2 m-2 p-2 d-inline-flex  justify-content-center align-items-center' >

                <div className='position-relative d-inline-block' style={{ backgroundImage: `url(${ScoreIcon})`, width: '3em', height: '3em', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>

                </div>

                
                <span className='ms-1'><b> {pointsData.points}</b></span>
            </div>
            {pointsData.theme_points != null ?
                <div style={{ backgroundColor:  bg }} className='rounded-4 ms-2 m-2 p-2 d-inline-flex  justify-content-center align-items-center' >

                    <div className='position-relative d-inline-block' style={{ backgroundImage: `url(${ThemeIcon})`, width: '3em', height: '3em', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>

                    </div>

                   
                    <span className='ms-1'><b> {pointsData.theme_points}</b></span>
                </div> : null
            }
            <div style={{ backgroundColor: bg }} className='rounded-4 ms-2 me-2 p-2  d-inline-flex  justify-content-center align-items-center' >

                <div className='position-relative d-inline-block' style={{ backgroundImage: `url(${HintIcon})`, width: '3em', height: '3em', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>

                </div>

               

                <span className='ms-1'><b>{pointsData.hint_points}</b></span>

            </div>

        </div>
    )
}

export default Points