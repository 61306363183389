import { useGetGameMetaDataQuery, useSendGuessMutation } from "../../redux/apiSlice";
import { useState } from "react";
import { Button, Tooltip } from "reactstrap";

const ShowAnswerButton = () => {

    const [tooltipOpen, setTooltipOpen] = useState(false)

    const { data: { isActive: isActiveGame, mode: currentGameMode } = { isActive: undefined, mode: undefined } } = useGetGameMetaDataQuery();
    const [sendGuess, { isLoading: isSending }] = useSendGuessMutation({ fixedCacheKey: 'shared-send-guess' });

    return (
        <div style={{ display: currentGameMode !== 'fast' ? 'block' : 'none' }} >
            <Tooltip

                isOpen={tooltipOpen}
                toggle={() => setTooltipOpen(tooltipOpen => !tooltipOpen)}
                placement="bottom"
                target="show-button"
            >
                нажмите, если хотите посмотреть правильный ответ (при этом игра завершится, а количество очков не изменится)
            </Tooltip>
            <Button id="show-button" disabled={isSending || !isActiveGame} className='mt-2 rounded-4 text-uppercase' block  color='primary' onClick={() => {

                sendGuess({ show_right_answer: true });

            }}>Показать ответ</Button>
        </div>
    )
}

export default ShowAnswerButton