import React, { useState } from 'react';

import {  useGetGameMetaDataQuery } from '../redux/apiSlice';

import {
    Spinner, Button, Collapse, Card,  ListGroup
} from 'reactstrap';
import { AiFillCaretDown } from 'react-icons/ai'
import ShowPromptOrStartGame from './ShowPromptOrStartGame';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';

const ChooseModeButton = () => {

    const { data: { isActive: isActiveGame, mode: currentGameMode } = { isActive: undefined, mode: undefined }, isFetching } = useGetGameMetaDataQuery();
  
    const modeNames = JSON.parse(process.env.REACT_APP_MODE_NAMES)

    const [isOpen, setIsOpen] = useState(false);
    
    const [newGame, setNewGame] = useState(false)
    const [newMode, setNewMode] = useState(null)

    const LogoLink = styled.span`
    cursor:pointer;
    position: relative;
    text-align: center;
    margin: 0.7em;
    &:hover::after {
      transform: scale(0)
    }
    &:after {

      content: "";
      position: absolute;
      bottom: -0.2em;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(73,162,255,1) 25%, rgba(73,162,255,1) 75%, rgba(255,255,255,0) 100%);
        transition: transform 500ms;
      
    }
  `;
    const handleClick = (name) => {

        setIsOpen(false);
        setNewMode(name);
        setNewGame(true);
  }

    const collapseRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    const handleClickOutside = (event) => {
      if (collapseRef.current && buttonRef.current && !collapseRef.current.contains(event.target) && !buttonRef.current.contains(event.target) ) {
        setIsOpen(false);
      }
    };
    return (<>
        {newGame ? <ShowPromptOrStartGame mode={newMode} setNewGame={setNewGame} /> : null}

        <Button innerRef={buttonRef} color="primary" disabled={newGame ? true : false} style={{ minWidth: '10em', zIndex: 999 }} onClick={ ()=>setIsOpen( isOpen => !isOpen ) }>

            {newGame ? <Spinner size='sm' /> : <> {modeNames[currentGameMode]} <AiFillCaretDown /></>}

        </Button>


        <div ref={collapseRef} style={{ zIndex: 999, width: '18em', position: 'absolute', top: '7em', right: '2em' }} >
            <Collapse
                              
                isOpen={isOpen}
            >

                <Card style={{background:'linear-gradient( #47a0ff,rgb(30, 139, 196) )'}}   onBlur={()=>alert('fef')}>
                
                    <ListGroup flush className='bg-white p-2'>

                        {Object.entries(modeNames).map(([shortName, fullName]) => 
                       
                        <LogoLink onClick={() => handleClick(shortName)}><span className='text-uppercase comfortaa fw-bold'>{fullName}</span></LogoLink>
                        
                        )
                        }

                    </ListGroup>
                </Card>
            </Collapse>
        </div>
    </>)
}

export default ChooseModeButton;