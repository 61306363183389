import { useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Timer from './Timer';
import { useSelector } from 'react-redux';
//import { setCurrentMessage } from '../../redux/mapSlice';
import 'react-circular-progressbar/dist/styles.css';
import { Progress } from 'reactstrap';
export const Socket = ({ socketData, setSocketData }) => {

  const ws_scheme = window.location.protocol == "https:" ? "wss" : "ws";
  const socketUrl = `${ws_scheme}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/ws/socket-server/`;
  const currentGuess = useSelector(state => state.map.currentGuess)
  const mapState = useSelector(state => state.map)
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl,
    {
      shouldReconnect: (closeEvent) => {
        /*
        useWebSocket will handle unmounting for you, but this is an example of a 
        case in which you would not want it to automatically reconnect
      */
        return true;
      },
      reconnectAttempts: 10,
      reconnectInterval: 0,
    });

  useEffect(() => {

    if (currentGuess) { sendMessage(JSON.stringify(currentGuess)) }

  }, [currentGuess])

  useEffect(() => {

    if (lastMessage !== null) {

      setSocketData(JSON.parse(lastMessage.data))
    }
  }, [lastMessage]);



  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <div >

      {socketData &&
        <Timer connectionStatus={connectionStatus} fetching={mapState.fetching} time={socketData.time} />
      }


    </div >
  );
};
