import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import Earth from './Earth';
import { NavLink as RRNavLink } from 'react-router-dom';

import { useStartGameMutation } from '../redux/apiSlice';

const GameFinishedModal = () => {

    const [startGame, { }] = useStartGameMutation();
    const [isOpen, setIsOpen] = useState(true)
    const toggle = () => setIsOpen(isOpen => !isOpen)
    return (

        <Modal isOpen={isOpen} toggle={toggle} className='position-absolute top-50 start-50 translate-middle' >
            <ModalHeader toggle={toggle}>Ну как, понравилось?</ModalHeader>
            <ModalBody>
                <div style={{ width: '12em' }} className='m-auto p-3'> <Earth /></div>
                <p className='fs-5 text-center'>
                    <div className='mb-2'> Может, еще разок? </div>
                    <div className='mb-2'>Тогда нажимайте <Button color='primary' onClick={() => startGame().then(() => setIsOpen(false))}>начать новую игру</Button> </div>
                    Кстати, у нас есть и другие <Button to="/about" tag={RRNavLink} color="primary" >типы игры</Button> 
                    

                </p>
            </ModalBody>

        </Modal>
    );
}

export default GameFinishedModal;