
import 'leaflet/dist/leaflet.css';

import { useGetProfileQuery } from '../../redux/apiSlice'
import { Table, Card, Button, Spinner } from 'reactstrap';

import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';



const GameTypeBar = ({ data }) => {
    const totalPlayed = data.reduce((total, gameType) => total + gameType.info['Всего сыграно'], 0);
    const generateGradient = (index) => {
        const hue = (index * 50) % 360;
        const saturation = 80;
        const lightness = 40; // Adjust the lightness value to make colors darker
        const color1 = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        const color2 = `hsl(${hue + 20}, ${saturation}%, ${lightness}%)`;

        return `linear-gradient(to right, ${color1}, ${color2})`;
    };
    return (
        <div>
            <div className="progress" style={{ height: '3em', backgroundColor: '#f5f5f5' }}>
                <div
                    className="progress-bar rounded fw-bold w-100 m-1"
                    role="progressbar"
                    style={{ height: '3em', background: 'linear-gradient( to right, #47a0ff,rgb(30, 139, 196) ) ' }}

                >  {totalPlayed} </div>
            </div>
            <div className="progress" style={{ height: '3em', backgroundColor: '#f5f5f5' }}>
                {data.map((gameType, index) => {
                    const { title, info } = gameType;
                    const { 'Всего сыграно': played } = info;
                    const segmentLength = (played / totalPlayed) * 100;
                    const gradient = generateGradient(index);

                    return (
                        <div
                            key={index}
                            className="progress-bar m-1 rounded fw-bold"
                            role="progressbar"
                            style={{ width: `${segmentLength}%`, background: gradient }}
                            aria-valuenow={segmentLength}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        > {played} </div>
                    );
                })}



            </div>

            <div className='d-flex mt-2 justify-content-center flex-wrap' >
                {data.map((gameType, index) => {
                    const { title } = gameType;
                    const gradient = generateGradient(index);

                    return (
                        <div key={index} className='d-flex align-items-center justify-content-center me-2'>
                            <div className='me-1 rounded'
                                style={{
                                    width: '1em',
                                    height: '1em',
                                    background: gradient,

                                }}
                            />
                            <span className="text-uppercase">{title}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};


const GameTypeData = ({ data }) => {

    return data.map((game_type, index) =>
        <>

            <div className='d-flex align-items-center justify-content-between'>
                <div className='m-3'>
                    <span className='text-uppercase'>всего сыграно: <b>{game_type.info['Всего сыграно']}</b></span>
                </div>
                <div className='fs-2 text-uppercase comfortaa m-2 text-end' >
                    {game_type.title}
                </div>
            </div>
            <Card className='shadow-sm'>
                <Table hover >

                    <tbody>
                        {
                            Object.entries(game_type.info).map(([key, val]) =>
                                key === 'Всего сыграно' ? null :
                                    <tr>
                                        <td className='w-100 text-start'>
                                            <div className='text-light d-inline-block rounded-3 p-3' style={{ background: 'linear-gradient( to right, #47a0ff,rgb(30, 139, 196) ) ' }}> {key} </div>
                                        </td>
                                        <td className='w-100 text-end'>
                                            <div className='text-dark d-inline-block rounded-3 p-3' > {val} </div>
                                        </td>
                                    </tr>
                            )
                        }

                    </tbody>
                </Table>
            </Card></>
    )

}

const Stats = () => {

    const { userId } = useParams();

    const {
        data: statistics,
        isSuccess: statisticsLoaded,
        isFetching: statisticsFetching,

    } = useGetProfileQuery(userId != null ? { user_id: userId, data_type: 'statistics' } : { data_type: 'statistics' }); //,{refetchOnMountOrArgChange: true,}

    const [showAllUsers, setShowAllUsers] = useState(false)

    const {
        data: rating,
        isSuccess: ratingLoaded,
        isFetching: ratingFetching,

    } = useGetProfileQuery(userId != null ? { user_id: userId, data_type: `rating_${showAllUsers ? 'full' : 'short'}` } :
        { data_type: `rating_${showAllUsers ? 'full' : 'short'}` });


    const navigate = useNavigate();

    if (ratingFetching || statisticsFetching) {
        return <div className='w-100 h-100 d-flex justify-content-center align-items-center'> <Spinner size='lg'></Spinner></div>
    }

    else if (ratingLoaded && !ratingFetching && statisticsLoaded && !statisticsFetching) return (
        <>
            <GameTypeBar data={statistics} />
            <div className='fs-2 text-end text-uppercase comfortaa m-2' > общий рейтинг </div>

            <Card className='shadow-sm' >
                <Table hover>

                    <tbody>

                        {rating.map(user =>

                            <tr className={user.myself ? 'table-success fw-bold' : ''}>

                                <td>
                                    {user.position}
                                </td>
                                <td>
                                    {user.id != null ?
                                        <Button color='primary' onClick={() => navigate(`/profile/${user.id}`, { replace: true })} >{user.username}</Button> :
                                        <b>{user.username}</b>
                                    }
                                </td>
                                <td>
                                    {user.points}
                                </td>


                            </tr>)}


                    </tbody>
                </Table>
                {!showAllUsers ?
                    <Button onClick={() => setShowAllUsers(true)} color='primary' outline>показать всё</Button>
                    :
                    <Button onClick={() => setShowAllUsers(false)} color='primary' outline>скрыть</Button>

                }
            </Card>

            <hr></hr>

            <GameTypeData data={statistics} />

        </>
    )
}

export default Stats