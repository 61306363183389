import React, { useState, useEffect } from 'react';
import { useGetProfileQuery } from '../../redux/apiSlice';
import { Toast, ToastBody } from 'reactstrap';
import Carousel from "react-multi-carousel";
import { useParams } from 'react-router-dom';
import "react-multi-carousel/lib/styles.css";
import { Pannellum } from "pannellum-react";

const AchievementProgressBar = ({ achievements }) => {
    return (
        <div>
            {achievements.map((achievement, index) => (
                <div key={index} >

                    <fieldset class="border rounded-3 p-3">
                        <legend class="float-none w-auto px-3">
                            {achievement.name}
                        </legend>

                        <Toast className='mb-2'>
                            <ToastBody>
                                {achievement.description}
                            </ToastBody>
                        </Toast>


                        <div className="progress">
                            <div

                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: `${(achievement.value / achievement.threshold) * 100}%`, background: 'linear-gradient( to right, #47a0ff,rgb(30, 139, 196) ) '
                                }}
                                aria-valuenow={achievement.value}
                                aria-valuemin="0"
                                aria-valuemax={achievement.threshold}
                            >
                                {`${achievement.value}/${achievement.threshold}`}
                            </div>
                        </div>
                    </fieldset>
                </div>
            ))}
        </div>
    );
};


const Achievements = () => {

    const { userId } = useParams();

    const [selected, setSelected] = useState(0);
    const [obtained, setObtained] = useState([]);

    const [currentImageUrl, setCurrentImageUrl] = useState(null)

    const widthOfSingleItem = 200;

    const responsive = {
        xxl: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1400 },
            items: 5,

        },
        xl: {
            // the naming can be any, depends on you.
            breakpoint: { max: 1400, min: 1200 },
            items: 4,

        },
        lg: {
            breakpoint: { max: 1200, min: 992 },
            items: 3,
        },
        md: {
            breakpoint: { max: 992, min: 768 },
            items: 3,
        },
        sm: {
            breakpoint: { max: 768, min: 576 },
            items: 2,
        },
        xs: {
            breakpoint: { max: 576, min: 0 },
            items: 1,
        }
    };


    const {
        data: achievements = [],
        isSuccess,
        isFetching
    } = useGetProfileQuery(userId !=null ? {user_id:userId,data_type:'achievements_info'} : {data_type:'achievements_info'}); //,{refetchOnMountOrArgChange: true,}

    
    useEffect(() => {
        setObtained(achievements.filter(achievement => achievement?.obtained))
    }, [achievements])

    const updateImageUrl = () => {
        if (obtained.length) setCurrentImageUrl(`${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/django_static/media/achievements/${obtained[selected].classname}.png`)
    }

    useEffect(() => {
        updateImageUrl()
        }, [obtained, selected])

    if (isSuccess && !isFetching) return (
        <>
            {obtained.length ?
                <div>
                    <div className='w-100'>
                        <Carousel

                            containerClass='carousel-container-custom'
                            responsive={responsive}
                            infinite={true}>
                            {obtained.map((item, index) => (

                                
                                <div onClick={() => setSelected(index)} key={index} className='d-flex m-auto flex-shrink-0 align-items-center justify-content-center flex-column p-3 rounded-5' style={{ boxSizing: 'border-box', border: index === selected ? '3px solid #47a0ff' : 'none', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/django_static/media/achievements/${item.classname}_prev.png`, userSelect: 'none', height: '400px', maxWidth: widthOfSingleItem }}>
                                    <h4 style={{ hyphens: 'auto', wordWrap: 'break-word', maxWidth: widthOfSingleItem * 0.8 }} className='text-white text-center comfortaa fs-4 mb-3 text-uppercase'>{item.name}</h4>
                                    <div style={{ background: 'linear-gradient(to top right, #F6D242, #54B0F4, #EC4F61)' }} className='p-3 mt-3 rounded-3 text-center text-white fw-bold'>{item.goal}</div>

                                </div>
                                

                            ))}
                        </Carousel>
                    </div>

                    <div style={{ backgroundImage: `url(${currentImageUrl})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '600px', overflow: 'hidden' }} className='w-100 w-lg-75 ms-auto me-auto m-3 d-flex flex-shrink-1 flex-grow-1 position-relative justify-content-center align-items-center rounded-5 shadow-lg'>
                         { obtained[selected].classname !== "Panoramic" ?  
                        <img style={{ zIndex: 99, maxWidth: '100%', maxHeight: '600px' }} src={currentImageUrl} ></img>
                                :
                                <div style={{ zIndex: 99, width: '100%', height: '600px'}}>
                                <Pannellum
                                maxYaw={160}
                                minYaw={-120}
                                width="100%"
                                height="600px"
                                image={currentImageUrl}
                                pitch={10}
                             
                                hfov={110}
                                autoLoad
                              >
                    
                              </Pannellum>
                              </div>
                            }
                        <div style={{ backdropFilter: 'blur(2.5em)' }} className='position-absolute w-100 h-100'>

                        </div>
                        <div style={{ zIndex: 100 }} className='position-absolute bottom-0 w-100 bg-secondary opacity-75 text-center fw-bold text-white p-3'>
                            {obtained[selected].description}
                        </div>
                    </div>
                </div> : null
            }
            <AchievementProgressBar achievements={achievements.filter(achievement => achievement.value && !achievement.obtained)} />
        </>
    );
};

export default Achievements;
