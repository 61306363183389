import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import landscape from './landscape.jpg';
import magnifierFrame from './magnifier-frame.svg';
import magnifierHandle from './magnifier-handle.svg';

const skyColor1 = '#c9f6ff';
const skyColor2 = '#97ebf4';

const extendAnimation = keyframes`
  100% {
    width: 90%;
  }
  90% {
    width: 95%;
  }
  80% {
    width: 90%;
  }
  0% {
    width: 10%;
  }
`;

const collapseAnimation = keyframes`
  0% {
    width: 90%;
    opacity: 1;
  }
  10% {
    width: 95%;
  }
  20% {
    width: 90%;
  }
  100% {
    width: 10%;
    opacity: 0;
  }
`;

const MainContainer = styled.div`
  z-index: 999;
  background-repeat: repeat-x;
  background-color: white;
  background-size: auto 100%;
  position: absolute;
  border-radius: 100px;
  width: 90%;
  height: 30%;
  animation: ${props => props.show ? extendAnimation : collapseAnimation} 1s forwards;
`;

const MagnifierPic = styled.div`
  position: absolute;
  background-size: auto 100%;
  background-repeat: repeat-x;
  height: 100%;
  aspect-ratio: 1 / 1;
  transform: scale(1.3, 1.3);
  border-radius: 50%;
`;

const MagnifierFrame = styled.div`
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 99;
`;

const MagnifierHandle = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  transform: scaleX(-1);
  position: absolute;
  top: 76%;
  left: 76%;
  height: 70%;
  width: 70%;
  background-image: url(${magnifierHandle});
  z-index: 98;
`;

const LoadingAnimation = ({ isFetching, setAnimationPlaying }) => {
  const [show, setShow] = useState(false);
  const [backgroundPos, setBackgroundPos] = useState(0);

  useEffect(() => {

    if (isFetching) { setShow(true) ; }
    if (!isFetching) setShow(false);
  }, [isFetching]);


  useEffect(() => {
    
    setAnimationPlaying(true)

      const interval = setInterval(() => {
        setBackgroundPos(prevPos => prevPos - 0.5);
      }, 1);
      return () => clearInterval(interval);
   
  }, []);

  return (
    <MainContainer onAnimationEnd={ ()=> { if(!show) setAnimationPlaying(false) } } show={show} className={`start-50 top-50 translate-middle shadow-lg`} style={{
      backgroundPosition: backgroundPos,
      backgroundImage: `url(${landscape}), linear-gradient(${skyColor1}, ${skyColor2})`
    }}>
      <MagnifierPic style={{ backgroundPosition: `top 0px left ${backgroundPos}px`, backgroundImage: `url(${landscape}), linear-gradient(${skyColor1}, ${skyColor2})` }}>
        <MagnifierFrame style={{ backgroundImage: `url(${magnifierFrame})` }} />
        <MagnifierHandle />
      </MagnifierPic>
    </MainContainer>
  );
};

export default LoadingAnimation;
