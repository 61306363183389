import React, { useEffect, useState } from 'react'
import { useStartGameMutation } from '../../redux/apiSlice';
import { useSelector } from 'react-redux';
import CarouselComponent from '../CarouselComponent';
import MainComponent from './MainComponent';
import Hints from './Hints';
import CurrentGame from './CurrentGame';
import Check from './check.svg'
import Remove from './remove.svg'
import { useSendGuessMutation, useGetGameMetaDataQuery, useGetHintMutation, useGetProfileQuery } from '../../redux/apiSlice';
import GreetingModal from '../GreetingModal';
import GameFinishedModal from '../GameFinishedModal';

const Game = () => {

    const {
        data: userSettings
    } = useGetProfileQuery({data_type:'settings'}); //,{refetchOnMountOrArgChange: true,}
    const {data: firstVisit} = useGetProfileQuery({data_type:'first_visit'});

    const [icon, setIcon] = useState(null)

    const [firstGame, setFirstGame] = useState(false)

    const [sendGuess, { data: response, isLoading: isSending }] = useSendGuessMutation({ fixedCacheKey: 'shared-send-guess' });
    const [getHint, { isLoading: hintLoading, isError, isSuccess: isUsed }] = useGetHintMutation();

    const { data: { isActive: isActiveGame, mode: currentGameMode } = { isActive: undefined, mode: undefined } } = useGetGameMetaDataQuery();

    const gameFeedback = useSelector(state => state.map.gameFeedback)

    const [startGame, { }] = useStartGameMutation(); // null,{refetchOnMountOrArgChange: true,}

    useEffect(() => {

        if (gameFeedback.res != null) {
            if (gameFeedback.res === 'guessed') setIcon({ type: 'success', html: <div style={{width:'2em',height:'2em', backgroundSize:'contain', backgroundImage:`url(${Check})`}}></div> });
            else if (gameFeedback.res === 'failed') setIcon({ type: 'fail', html: <div style={{ width:'2em',height:'2em',backgroundSize:'contain', backgroundImage:`url(${Remove})`}}></div> });
            else setIcon(null)
        } else setIcon(null)

    }, [gameFeedback]);
    

    useEffect(() => {
      if ( firstVisit && response?.firstGame) setFirstGame(true)
     
    }, [response])
    

    useEffect(() => {

        if (!isActiveGame && userSettings?.settings_auto_restart.value) {

            setTimeout(() => {
                startGame()
            }, 1000);
        }

    }, [isActiveGame]);
 
    return (
        <>

        { firstGame ? <GameFinishedModal /> : null }
        { firstVisit ? <GreetingModal /> : null }
        <MainComponent
            LeftColumn={
                <CarouselComponent stream={false}  />
            }
            RightColumn={
                <div className='d-flex flex-lg-column'>
                    <CurrentGame icon={icon} isSending={isSending} />
                    <hr />
                    <Hints onClickAction={(name) => getHint(name)} hintLoading={hintLoading} isError={isError} isUsed={isUsed} />

                </div>
            }
        />
        </>
    )

}

export default Game
