import { useGetGameMetaDataQuery } from '../redux/apiSlice';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { IoLogoGameControllerB } from 'react-icons/io';
import styled from 'styled-components';

const GameIcon = styled.div`
  display: inline-block;
  background-color: green;
  border-radius: 50%;
  padding: 5px;
  transition: 200ms;
  opacity: 0.5;
  position: fixed;
  right: 5vh;
  bottom: 10vh;
  z-index: 999;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    opacity: 0.9;
    transition: 200ms;
  }
`;

const BackToGame = () => {
  const { data: { isActive: isActiveGame } = { isActive: undefined, mode: undefined } } = useGetGameMetaDataQuery();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      {isActiveGame && !location.pathname.includes('game') && (
        <GameIcon onClick={() => navigate('/game', { replace: true })}>
          <IoLogoGameControllerB color='white' size={69} />
          <div className='position-absolute start-50 top-100 mt-2 p-1 translate-middle-x w-100 text-center bg-light rounded'>назад к игре</div>
        </GameIcon>
      )}
    </>
  );
};

export default BackToGame;

