import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import { useCheckIfLoggedQuery, useLoginMutation } from '../redux/apiSlice';
import Login from './Login';
import Register from './Register';
const AuthModal = ({ modal, toggle }) => {

    const [showSignup, setShowSignup] = useState(false)

    return (
        <div>

            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Войти</ModalHeader>
                <ModalBody>
                    <Login toggle={toggle} showSignup={showSignup} setShowSignup={setShowSignup} />

                    {showSignup &&
                        <>
                            <br></br>
                            <Register toggle={toggle} />
                        </>
                    }
                </ModalBody>

            </Modal>
        </div>
    );
}

export default AuthModal;