
import { Spinner, Collapse, Table } from 'reactstrap'
import { useGetThemesQuery, useSetSettingsMutation, useSetThemeMutation, useGetProfileQuery } from '../redux/apiSlice'
import { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';

const CountdownComponent = ({ ends_at }) => {

    const [finalDate, setFinalDate] = useState(null);
    const [remainingTime, setRemainingTime] = useState(null);

    useEffect(() => {

        setFinalDate(new Date(ends_at));

    }, []);

    useEffect(() => {

        if (finalDate) {
            const interval = setInterval(() => {

                const currentDate = new Date();

                if (currentDate >= finalDate) {
                    clearInterval(interval);
                    setRemainingTime('завершено');
                } else {

                    const timeDiff = finalDate.getTime() - currentDate.getTime();
                    const days = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
                    const hours = Math.floor((timeDiff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
                    const minutes = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));

                    setRemainingTime(`${days} д, ${hours} ч, ${minutes} м`);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [finalDate]);

    return (
        <span className='fw-bold'>{remainingTime}</span>
    );
};

const gradientStartColor = [73, 162, 255, 1]
const gradientEndColor = [30, 139, 196, 1]; //[75, 75, 255, 1]

const responsive = {

    all: {
        breakpoint: { max: 4000, min: 0 },
        items: 1,
    }
};

const ThemeModePage = () => {

    const navigate = useNavigate();

    const [setTheme, { }] = useSetThemeMutation();
    const [setSettings, { }] = useSetSettingsMutation();
    const [themeInfoOpen, setThemeInfoOpen] = useState(Array(10).fill(false))

    const {
        data: settings, isSuccess: settingsLoaded, isFetching: settingsFetching
    } = useGetProfileQuery({ data_type: 'settings' }); //,{refetchOnMountOrArgChange: true,}

    const {
        data, isSuccess, isFetching, isError
    } = useGetThemesQuery(); //,{refetchOnMountOrArgChange: true,}
    const {
        data: currentTheme, isSuccess: currentThemeLoaded, isFetching: currentThemeFetching
    } = useGetProfileQuery({ data_type: 'theme' }); //,{refetchOnMountOrArgChange: true,}

    if (isFetching || settingsFetching || currentThemeFetching) {
        return <div className='w-100 h-100 d-flex justify-content-center align-items-center'> <Spinner size='lg'></Spinner></div>
    }
    else if (isError) {
        return <div className='w-100 h-100 d-flex justify-content-center align-items-center'> В настоящий момент эта страница недоступна</div>
    }

    else if (isSuccess && settingsLoaded && currentThemeLoaded) {

        return (
            <>
                <div className='w-100 w-lg-75 m-auto p-3 mt-3 rounded-3' style={{ background: 'linear-gradient(0deg, rgb(30, 139, 196, 1) 0%, rgba(73, 162, 255, 1) 100%)' }}
                >

                    { data.activeTheme ?
                        <div className='w-100 p-3 mt-3' style={{ aspectRatio: '1.5/1', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `url(${data.activeTheme.map_preview})` }}>
                            <div className='d-flex align-items-center justify-content-between p-3'>
                                <div className='fs-3 comfortaa text-white'> тема месяца: <b>{data.activeTheme.title}</b></div>
                                <div className='fs-5 text-white'> до завершения осталось: <CountdownComponent ends_at={data.activeTheme.ends_at}></CountdownComponent></div>
                            </div>
                            <div className='d-flex flex-column flex-lg-row ' style={{ rowGap: '1em', columnGap: '1em' }}>
                                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} className='w-100 w-lg-50 text-white rounded shadow-lg'>
                                    <Carousel


                                        responsive={responsive}
                                        infinite={false}
                                    >
                                        
                                        {data.activeTheme.description.map(info => <div className='m-3'>
                                             <span className='fs-3' >{info.title}</span> <p>{info.text}</p>  </div>)}
                                    </Carousel>

                                </div>
                                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} className='d-flex flex-column w-100 w-lg-50 text-white  p-3 rounded shadow-lg'>
                                    <ul>
                                        <li> <FaMapMarkerAlt color='white' size={16} /> при включенном тематическом режиме все фотографии, которые вы увидите, будут посвящены текущей теме </li>
                                        <li> <FaMapMarkerAlt color='white' size={16} /> вы будете видеть более детализированную карту территории, которой посвящен тематический месяц </li>
                                        <li> <FaMapMarkerAlt color='white' size={16} /> тематический режим можно отключить и включить в любой момент </li>
                                        <li> <FaMapMarkerAlt color='white' size={16} /> среди пользователей, играющих с включенным тематическим режимом, ведется отдельный рейтинг </li>

                                    </ul>
                                    {settings.settings_theme_mode_on.value && data.activeTheme.title === currentTheme ?
                                        <Button onClick={() => setSettings({ settings_theme_mode_on: false })} color='secondary' outline>выключить</Button>
                                        :
                                        <Button onClick={() => { setTheme({ title: data.activeTheme.title }).then(() => setSettings({ settings_theme_mode_on: true })) }} color='primary' outline>включить</Button>
                                    }
                                </div>
                            </div>
                            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} className='mt-3 w-100 text-white p-3 rounded shadow-lg'>


                                <div className='text-center text-uppercase comfortaa mt-3'>{data.activeTheme.top_name}</div>
                                <div style={{ maxHeight: '20em', overflowY: 'scroll' }}>
                                    <Table hover borderless={true} >
                                        <thead >

                                            <tr>
                                                <th className='text-uppercase text-white'>
                                                    имя
                                                </th>

                                                <th className='text-uppercase text-white'>
                                                    очки
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody className='bg-white opacity-75 rounded'>

                                            {data.activeTheme.rating.map((data) =>

                                                <tr >
                                                    <td className='fw-bold text-start w-100'>
                                                        {data.id != null ?
                                                            <Button onClick={() => navigate(`/profile/${data.id}`, { replace: true })} color='secondary' outline>{data.username}</Button> :
                                                            <b>{data.username}</b>
                                                        }
                                                    </td>
                                                    <td className='fw-bold w-100 text-end'>
                                                        {data.points}
                                                    </td>

                                                </tr>)}


                                        </tbody>

                                    </Table></div>
                            </div>


                        </div> : 
                        <div className='fs-5 comfortaa text-white'>
                            В настоящий момент тема месяца отсутствует, вы можете выбрать одну из архивных тем
                        </div>
                    }
                </div>
                <div className='w-100 w-lg-75 m-auto mt-3'>
                    <div className='fs-2 text-end text-uppercase comfortaa m-2' > архив </div>

                    {
                        data.archivedThemes.map((theme, index) =>

                            <div className='w-100 m-auto'>

                                <div onClick={() => setThemeInfoOpen(themeInfoOpen.map((val, ind) => ind === index ? !val : val))} className='w-100 me-2 d-flex align-items-center  p-3 text-light' style={{ borderTopLeftRadius: index === 0 ? '2em' : 0, borderTopRightRadius: index === 0 ? '2em' : 0, borderBottomLeftRadius: index === data.archivedThemes.length - 1 ? '2em' : 0, borderBottomRightRadius: index === data.archivedThemes.length - 1 ? '2em' : 0, background: `linear-gradient(180deg, rgba( ${gradientStartColor.map((color, i) => (color + (gradientEndColor[i] - color) / data.archivedThemes.length * index)).join()} ) 0%, rgba(${gradientStartColor.map((color, i) => (color + (gradientEndColor[i] - color) / data.archivedThemes.length * (index + 1))).join()}) 100%)` }}>

                                    <div className='fs-2 d-flex w-100 justify-content-between  text-uppercase comfortaa' >
                                        <div>{theme.title}</div>

                                        {settings.settings_theme_mode_on.value && theme.title === currentTheme ?
                                            <Button onClick={() => setSettings({ settings_theme_mode_on: false })} color='primary' outline >выключить</Button>
                                            :
                                            <Button onClick={() => { setTheme({ title: theme.title }).then(() => setSettings({ settings_theme_mode_on: true })) }} color='primary' outline >включить</Button>
                                        }

                                    </div>
                                </div>
                                <Collapse isOpen={themeInfoOpen[index]}>

                                    <div className='w-100 p-3 rounded-3' style={{ background: 'linear-gradient(0deg, rgb(30, 139, 196, 1) 0%, rgba(73, 162, 255, 1) 100%)' }}
                                    >


                                        <div className='w-100 p-3 mt-3' style={{ aspectRatio: '1.5/1', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `url(${theme.map_preview})` }}>

                                            <div className='d-flex flex-column flex-lg-row' style={{ rowGap: '1em', columnGap: '1em' }}>
                                                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} className='w-100 flex-shrink-0 w-lg-50 text-white rounded shadow-lg'>
                                                    {themeInfoOpen[index] ? <Carousel

                                                        key={index}
                                                        responsive={responsive}
                                                        infinite={false}
                                                    >

                                                        {theme.description.map(info => <div className='m-3'> <h3>{info.title}</h3> {info.text} </div>)}
                                                    </Carousel> : null
                                                    }
                                                </div>
                                                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} className='w-100 text-white p-3 rounded shadow-lg'>


                                                    <div className='text-center text-uppercase comfortaa mt-3'>{theme.top_name}</div>
                                                    <div style={{ maxHeight: '20em', overflowY: 'scroll' }}>
                                                        <Table hover borderless={true} >
                                                            <thead >

                                                                <tr>
                                                                    <th className='text-uppercase text-white'>
                                                                        имя
                                                                    </th>

                                                                    <th className='text-uppercase text-white'>
                                                                        очки
                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className='bg-white opacity-75 rounded'>

                                                                {theme.rating.map((data) =>

                                                                    <tr >
                                                                        <td className='fw-bold text-start w-100'>
                                                                            {data.id != null ?
                                                                                <Button onClick={() => navigate(`/profile/${data.id}`, { replace: true })} color='primary' >{data.username}</Button> :
                                                                                <b>{data.username}</b>
                                                                            }
                                                                        </td>
                                                                        <td className='fw-bold w-100 text-end'>
                                                                            {data.points}
                                                                        </td>

                                                                    </tr>)}


                                                            </tbody>

                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>

                                    </div>

                                </Collapse>

                            </div>

                        )
                    }
                </div>
            </>)
    }

}

export default ThemeModePage