
import EarthPic from './earth_italy_minified.svg'

import PizzaSliceImage from './pizza_slice_minified.svg'

import styled from 'styled-components';

const EarthContainer = styled.div`
  flex-basis: 15em;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  background-image: url("${EarthPic}");
  background-size: cover;
  position: relative;
  transform-style: preserve-3d;
  border-radius: 50%;
`;

const PizzaSlice = styled.div`
@keyframes bounce {
  0% { transform: translateY(0px)    };
  50% { transform: translateY(10px)  };
  100% { transform: translateY(0px)  }
}
    background-image: url('${PizzaSliceImage}');
    background-size: contain;
    background-repeat:no-repeat;
    width: 60%;
    height:60%;
    position: absolute;
    top: 0%;
    left:50%;
    animation: bounce 2s linear infinite
 `;
const EarthItaly = () => {

  return <EarthContainer>
    <PizzaSlice></PizzaSlice>

  </EarthContainer>
}

export default EarthItaly