import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import Earth from './Earth';
const GreetingModal = () => {
    const [isOpen,setIsOpen] = useState(true)
    const toggle = () => setIsOpen(isOpen => !isOpen) 
    return (
      
            <Modal isOpen={isOpen} toggle={toggle} className='position-absolute top-50 start-50 translate-middle' >
                <ModalHeader  toggle={toggle}>Добро пожаловать!</ModalHeader>
                <ModalBody>
                        <div style={{width:'12em'}} className='m-auto p-3'> <Earth/></div>
                        <p className='fs-4'>
                        Как думаете, где сделано это фото? Выберите точку на карте и нажмите <b>ГОТОВО</b>
                        </p>
                        <Button onClick={toggle}
                            color='primary' className="ms-auto me-auto m-2 d-block" >
                            ОК
                        </Button>

                 
                </ModalBody>

            </Modal>
        
    );
}

export default GreetingModal;