import {configureStore} from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';

import mapReducer from './mapSlice';
import mapStreamReducer from './mapSliceStream';

const asyncDispatchMiddleware = store => next => action => {
    let syncActivityFinished = false;
    let actionQueue = [];
  
    function flushQueue() {
      actionQueue.forEach(a => store.dispatch(a)); // flush queue
      actionQueue = [];
    }
  
    function asyncDispatch(asyncAction) {
      actionQueue = actionQueue.concat([asyncAction]);
  
      if (syncActivityFinished) {
        flushQueue();
      }
    }
  
    const actionWithAsyncDispatch =
        Object.assign({}, action, { asyncDispatch, globalState:store.getState()  } );

   
    next(actionWithAsyncDispatch);
    syncActivityFinished = true;
    flushQueue();
  };
  
  

const store = configureStore( {

    reducer: {
   
        map: mapReducer,
        mapStream: mapStreamReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
       
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware).concat(asyncDispatchMiddleware)
}
);



export default store;