import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { useSignupMutation } from '../redux/apiSlice';


const Register = ({ toggle }) => {

  const fields = [
    {
      id: "LoginField",
      name: "username",
      label: "Логин",
      type: "text",
      validators: [(text) => text ? { valid: true } : { valid: false, message: 'Это обязательное поле' }]
    },
    {
      id: "Password1Field",
      name: "password1",
      label: "Пароль",
      type: "password",
      validators: [(text) => text ? { valid: true } : { valid: false, message: 'Это обязательное поле' }]
    },
    {
      id: "Password2Field",
      name: "password2",
      label: "Еще раз пароль",
      type: "password",
      validators: [(text) => text === formData.password1 ? { valid: true } : { valid: false, message: 'Пароли не совпадают' }]
    },
  ]

  const [signup, { isSuccess, isError, error }] = useSignupMutation()

  const [formData, setFormData] = useState(Object.assign(...fields.map(field => field.name).map(k => ({ [k]: null }))));
  const [currentError, setCurrentError] = useState({})

  const onChange = e => {

    setCurrentError({})
    setFormData({ ...formData, [e.target.name]: e.target.value });

  }

  const onSubmit = async (e) => {

    e.preventDefault();
    let errors = fields.map(field => {
      let validation_res = field.validators.map(validator => validator(formData[field.name])).filter(i => !i.valid);

      if (validation_res.length) return { [field.name]: validation_res.map(res => res.message) }
    }
    ).filter(i => i)

    if (errors.length) {
      setCurrentError({ ...currentError, ...Object.assign(...errors) })
    }
    if (!errors.length) await signup(formData).unwrap()
      .then(res => setTimeout(() => toggle(), 1000))
      .catch(error => {

        setCurrentError({
          ...currentError, ...Object.entries(JSON.parse(error.data.detail)).reduce((p, [k, v]) => ({ ...p, [k]: v.map(singleErr => singleErr.message) }), {})
        });

      })
  }

  return (
    <Form onSubmit={e => onSubmit(e)}>

      {fields.map(field => <FormGroup floating>

        <Input invalid={!!currentError[field.name]?.length} valid={isSuccess} onChange={e => onChange(e)}
          id={field.id}
          name={field.name}
          type={field.type}
        />

        <Label for={field.id}>
          {field.label}
        </Label>
        {
          currentError[field.name] ?
            <FormFeedback tooltip>
              {currentError[field.name].map(msg => <div>{msg}</div>)}
            </FormFeedback> : null
        }

      </FormGroup>)}

      <Button type='submit'>
        Зарегистрироваться
      </Button>

    </Form>
  )
}

export default Register