import { useGetProfileQuery } from '../../redux/apiSlice'

import 'leaflet/dist/leaflet.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Pannellum } from "pannellum-react";

import { FaMapMarkedAlt } from 'react-icons/fa';

import { useState, useEffect, useCallback } from 'react';
import { Table, Alert, Spinner, Modal, ModalBody, ModalHeader, Button, UncontrolledCollapse, Card, CardBody } from 'reactstrap';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import * as ReactDOMServer from 'react-dom/server';

import { AiOutlineLink, AiFillHeart } from 'react-icons/ai';

import { FaListAlt, FaEye } from 'react-icons/fa';
import { BsClipboard, BsClipboardCheck } from 'react-icons/bs';
import { useParams } from 'react-router-dom';

const Liked = () => {

    const { userId } = useParams();


    const [copied, setCopied] = useState({})
    const [isMap, setIsMap] = useState(true)
    const [currentImageUrl, setCurrentImageUrl] = useState(null)


    const toggle = () => setIsMap(isMap => !isMap)

    const {
        data,
        isSuccess,
        isFetching,
        isError,
        error
    } = useGetProfileQuery(userId != null ? { user_id: userId, data_type: 'liked_info' } : { data_type: 'liked_info' }); //,{refetchOnMountOrArgChange: true,}

    const [modalOpen, setModalOpen] = useState(false);
    const [openedImages, setOpenedImages] = useState([]);
    const [isPano, setIsPano] = useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const measuredRef = useCallback((map, bounds) => {
        if (map !== null) {
            if (bounds?.length) map.target.fitBounds(bounds)
            map.target.setMaxZoom(7)

        }
    }, []);

    
    if (isFetching) {
        return <div className='w-100 h-100 d-flex justify-content-center align-items-center'> <Spinner size='lg'></Spinner></div>
    }

    else if (isSuccess && !isFetching) return (
        <>

            <div className='position-relative'>
                <div style={{ zIndex: 500, cursor: 'pointer' }} className='position-absolute end-0 top-0 m-2 bg-white rounded-2 opacity-75'>

                    {isMap ? <FaListAlt onClick={toggle} size={30} /> : <FaMapMarkedAlt onClick={toggle} size={30} />}

                </div>

                {
                    !isMap ?
                        <fieldset className="border rounded-3 p-3">
                            <legend className="float-none w-auto px-3">
                                <AiFillHeart

                                    color='red'
                                    size={36}
                                />
                            </legend>
                            <Table hover>

                                <tbody>

                                    {data.map((image, j) =>
                                        <>
                                            <tr index={j}>

                                                <td>
                                                    <a className='me-2' href={`https://www.mapillary.com/app/?pKey=${image.url}&focus=photo`}>

                                                        <AiOutlineLink size={20} />
                                                    </a>
                                                    <p className='genos fs-4 d-inline-block'> {`${image.coords[0]},${image.coords[1]}`}
                                                        <CopyToClipboard text={`${image.coords[0]},${image.coords[1]}`}

                                                            onCopy={() => { setCopied(j) }} >
                                                            {
                                                                copied === j ? <><BsClipboardCheck className='ms-2' size={16} />
                                                                    <Alert className='p-2 d-inline-block ms-2 m-0' color="success">
                                                                        <span style={{ fontFamily: 'var(--bs-body-font-family)' }} className='fs-6'> скопировано </span>
                                                                    </Alert></> : <BsClipboard className='ms-2' size={16} style={{ cursor: 'pointer' }} />
                                                            }
                                                        </CopyToClipboard>
                                                    </p>
                                                </td>
                                                <td className='text-end'>
                                                    <Button
                                                        onClick={() => setOpenedImages(openedImages => [...openedImages, j])}
                                                        color="primary"
                                                        id={`toggler${j}`}
                                                        className='ms-auto'

                                                    >
                                                        <FaEye color='white' size={20} />
                                                    </Button>
                                                </td>


                                            </tr>
                                            <tr>
                                                <td colSpan={2}>

                                                    <UncontrolledCollapse toggler={`#toggler${j}`}>
                                                        {openedImages.includes(j) ?
                                                            <div onClick={() => {
                                                                setCurrentImageUrl(
                                                                    `${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}${image.url}`
                                                                );
                                                                if (!image.is_pano) { toggleModal() }
                                                                setIsPano(image.is_pano);

                                                            }} style={{ cursor: 'pointer', backgroundImage: `url(${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}${image.url})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', overflow: 'hidden', maxHeight: '600px' }} className='ms-auto me-auto w-100 d-flex flex-shrink-1 flex-grow-1 position-relative justify-content-center align-items-center '>

                                                                {!image.is_pano ?
                                                                    <img style={{ zIndex: 99, maxHeight: '600px', maxWidth: '100%' }} src={`${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}${image.url}`
                                                                    } ></img>
                                                                    :
                                                                    <div style={{ zIndex: 99, width: '100%' }}>
                                                                        <Pannellum
                                                                            width="100%"

                                                                            image={`${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}${image.url}`
                                                                            }
                                                                            pitch={10}
                                                                            yaw={180}
                                                                            hfov={110}
                                                                            autoLoad
                                                                        >

                                                                        </Pannellum>
                                                                    </div>
                                                                }
                                                                <div style={{ backdropFilter: 'blur(2.5em)' }} className='position-absolute w-100 h-100'>

                                                                </div>

                                                            </div> :
                                                            null
                                                        }
                                                    </UncontrolledCollapse>
                                                </td>
                                            </tr>
                                        </>
                                    )}


                                </tbody>
                            </Table>

                        </fieldset>
                        :
                        <div className='d-flex flex-lg-row flex-column'>

                            <div className='w-lg-50 w-100' style={{ aspectRatio: '1.5/1' }} >

                                <MapContainer whenReady={self => measuredRef(self, data.map(image => image.coords))} center={[51.505, -0.09]} zoom={2} maxZoom={2} scrollWheelZoom={true}>

                                    <TileLayer
                                        url={`${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/django_static/static/tiles/{z}_{x}_{y}.png`}
                                    />

                                    {data.map((image, i) => <Marker

                                        eventHandlers={{
                                            click: e => {
                                                setIsPano(image.is_pano);
                                                setCurrentImageUrl(
                                                    `${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}${image.url}`
                                                )
                                            }
                                        }}
                                        icon={L.divIcon({

                                            className: "custom-icon", html: ReactDOMServer.renderToString(

                                                <div className={`custom-marker-container`}>

                                                    <AiFillHeart

                                                        color='red'
                                                        size={36}
                                                    />

                                                </div>


                                            )
                                        })} position={image.coords} >
                                        <Popup> {image.coords[0]}, {image.coords[1]}
                                            <CopyToClipboard text={`${image.coords[0]},${image.coords[1]}`}

                                                onCopy={() => { setCopied(i) }} >
                                                {
                                                    copied === i ? <BsClipboardCheck className='ms-2' size={16} style={{ cursor: 'pointer' }} />
                                                        : <BsClipboard className='ms-2' size={16} style={{ cursor: 'pointer' }} />
                                                }
                                            </CopyToClipboard>
                                        </Popup>
                                    </Marker>
                                    )}

                                </MapContainer>
                            </div>

                            {currentImageUrl ?

                                <div onClick={ ()=>{ if(!isPano) toggleModal() } } style={{ cursor: 'pointer', backgroundImage: `url(${currentImageUrl})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', overflow: 'hidden' }} className='position-relative w-100 w-lg-50 d-flex  justify-content-center align-items-center '>
                                    {!isPano ?
                                        <img style={{ zIndex: 99 }} className='w-100' src={currentImageUrl} ></img>
                                        :
                                        <div style={{ zIndex: 99, width: '100%' }}>
                                            <Pannellum
                                                width="100%"

                                                image={currentImageUrl}
                                                pitch={10}
                                                yaw={180}
                                                hfov={110}
                                                autoLoad
                                            >

                                            </Pannellum>
                                        </div>}
                                    <div style={{ backdropFilter: 'blur(2.5em)' }} className='position-absolute w-100 h-100'>

                                    </div>

                                </div> :
                                <div className='w-100 w-lg-50 shadow-lg' style={{ borderBottomRightRadius: '1em', borderTopRightRadius: '1em' }}>
                                    <img className='w-100' src={`${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/django_static/static/placeholder-image.png`}></img>

                                </div>
                            }
                        </div>
                }



            </div>

            <div style={{ zIndex: 1000 }}>
                <Modal isOpen={modalOpen} fullscreen={true} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}></ModalHeader>
                    <ModalBody className='text-center'>
                        <div className='w-100 d-flex align-items-center justify-content-center'>
                            <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={currentImageUrl} />
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}

export default Liked