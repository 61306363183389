import { useEffect, useState } from 'react';
import {
  Button, Spinner,
} from 'reactstrap';

import { useLazyGetGameDataQuery, useGetGameMetaDataQuery, useSetSettingsMutation, useGetProfileQuery, useUpdateStreamMutation, useImageFeedbackMutation } from '../redux/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentImage, updateMarker } from '../redux/mapSlice';
import { setCurrentImage as setCurrentImageStream } from '../redux/mapSliceStream';
import LoadingAnimation from './LoadingAnimation';
import styled, { keyframes } from 'styled-components';
import { Pannellum } from "pannellum-react";
import { GrNext, GrPrevious } from 'react-icons/gr'
import { IoMdAlbums } from 'react-icons/io';
import { BsGridFill, BsHandThumbsDown } from 'react-icons/bs';
import { FcInfo } from 'react-icons/fc';
import { AiFillHeart } from 'react-icons/ai';
import { useParams } from 'react-router-dom';

const LikeDislike = styled.div`
  opacity: ${props => (props.pressed ? 1 : 0.5)};
  transition: opacity 0.3s ease-in-out;
  display: inline-block;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

`;

const Slides = ({ item, setItems, itemsNum, currentImage, next, previous }) => {
  const { userId } = useParams();
  
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const [sendFeedback, { }] = useImageFeedbackMutation();


  return item.loaded ? <div className='d-flex'>
    {
      (item.id !== undefined) &&
      <Button style={{ zIndex: 100 }} color="dark" disabled pill className='position-absolute start-50 translate-middle-x mt-3 fs-5'>{item.additional ? 'доп.' : null} фото <b>{currentImage}</b> {itemsNum > 1 ? `из ${itemsNum}` : ''}
        {item.additional ? <p className='fs-6 m-0'>около {item.distance} км до первого фото</p> : null}
      </Button>
    }
    <div onClick={itemsNum > 1 ? previous : null} className='d-flex flex-grow-1 justify-content-end align-items-center'>
      {itemsNum > 1 ?
        <GrPrevious size={30} style={{ opacity: 0.6 }} />
        : null
      }
    </div>


    <div style={{ background: `url(${item.full})`, flexBasis: '1024px', aspectRatio: '4 / 3', overflow: 'hidden' }} className='d-flex flex-shrink-1 flex-grow-1 position-relative justify-content-center align-items-center'>

      {item.is_pano ?
        <div style={{ zIndex: 99 }} className='w-100'>
          <Pannellum

            image={item.full}
            pitch={10}
            yaw={180}
            hfov={110}
            autoLoad
          >

          </Pannellum>
        </div>
        :

        <FullImage className='shadow-lg w-100' style={{ zIndex: 99, display: item.loaded ? 'block' : 'none' }} src={item.full} ></FullImage>

      }
      <div style={{ backdropFilter: 'blur(0.5em)' }} className='position-absolute w-100 h-100'>

      </div>
      <div className='m-3 position-absolute bottom-0 end-0' style={{ zIndex: 101 }}>
        <LikeDislike pressed={liked[item.id]} >
          <AiFillHeart onClick={() => sendFeedback({ currentImage: currentImage - 1, additional:item.additional ? true : false, action: 'like', user_id: userId }).unwrap().then(() => { setLiked( liked => ({ ...liked, [item.id]:true }) ); setDisliked( disliked => ({ ...disliked, [item.id]:false }) ) } )} color='red' size={46} />
        </LikeDislike >
        <LikeDislike pressed={disliked[item.id]}>
          <BsHandThumbsDown onClick={() => sendFeedback({ currentImage: currentImage - 1, additional:item.additional ? true : false, action: 'dislike', user_id: userId }).unwrap().then(() => { setLiked( liked => ({ ...liked, [item.id]:false }) ); setDisliked( disliked => ({ ...disliked, [item.id]:true }) ) } )} color='white' size={36} />
        </LikeDislike >
      </div>
      {item.fact ?
        <div style={{ zIndex: 100, maxHeight: '100px', overflowY: 'auto' }} className='position-absolute bottom-0 bg-dark text-white p-3 w-100 opacity-75'>

          <span className='fw-bold'>{item.city} <FcInfo size={16} /></span>
          <p>{item.fact}</p>
        </div> : null
      }
    </div>

    <div onClick={itemsNum > 1 ? next : null} className='d-flex flex-grow-1 justify-content-start align-items-center'>
      {itemsNum > 1 ?
        <GrNext size={30} style={{ opacity: 0.6 }} />
        : null
      }
    </div>


  </div> :
    <div className='d-flex align-items-center justify-content-center m-auto' style={{ maxWidth: '1024px', aspectRatio: '4 / 3' }} > <Spinner size='lg'></Spinner> <img src={item.full} className='d-none' onLoad={() => setItems(items => items.map(item_ => ({ ...item_, loaded: item_.id === item.id ? true : item_.loaded })))} ></img></div>

}

const blurIn = keyframes`
  from {
    filter: blur(1em);
  }
  to {
    filter: blur(0);
  }
`;

const FullImage = styled.img`
  filter: blur(1em);
  animation: ${blurIn} 1s forwards;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

const CarouselComponent = ({ stream, fastMode }) => {

  const { data: { isActive: isActiveGame } = { isActive: undefined } } = useGetGameMetaDataQuery();
 
  const { data: settings } = useGetProfileQuery({ data_type: 'settings' }); 

  const [setSettings, { }] = useSetSettingsMutation();

  const dispatch = useDispatch();
  const mapState = useSelector(state => stream ? state.mapStream : state.map)

  const data = useSelector(state => stream ? state.mapStream.images : state.map.images)

  const [triggerGetGameData] = useLazyGetGameDataQuery();
  useEffect(() => {
    if (!stream && !fastMode) triggerGetGameData()
  }, [])

  const [activeIndex, setActiveIndex] = useState(0);

  const [items, setItems] = useState(null)

  const [updateStream, { }] = useUpdateStreamMutation();

  useEffect(() => {
    if (!stream && settings?.settings_streaming.value) updateStream({ fetching: mapState.fetching })
  }, [mapState.fetching])

  
  useEffect(() => {
    
    if (data && data.length) setItems(data.map((datum) => ({ ...datum, loaded: false })))
  }
    , [data])

  const [isGrid, setIsGrid] = useState(false);

  const next = (stream) => {

    stream ?

      dispatch(setCurrentImageStream(mapState.currentImage + 1)) 
      :

      dispatch(setCurrentImage(mapState.currentImage + 1))

    if (mapState.markers.length === 1) dispatch(updateMarker(0))
  };

  const previous = (stream) => {
    stream ?

    dispatch(setCurrentImageStream(mapState.currentImage - 1)) 
    :

    dispatch(setCurrentImage(mapState.currentImage - 1))

    if (mapState.markers.length === 1) dispatch(updateMarker(0))
  };

  useEffect(() => {

    if (items) {

      if (!(items.filter(item => !item.additional) > 1)) setIsGrid(false)
      else {
        if (settings.settings_display_grid.value) setIsGrid(true);
        else setIsGrid(false)
        
      }

    }

  }, [items])

  const GridImage = ({ id, imgUrl }) => {
    const selected = id === mapState.currentImage
    return <div style={{ background: `url(${imgUrl})` }} className="col p-4 d-flex align-items-center justify-content-center position-relative"><img style={{ zIndex: 99, cursor: 'pointer' }} onClick={() => { dispatch(setCurrentImage(id)); if (mapState.markers.length === 1) dispatch(updateMarker(0)) }} className={`rounded-3 w-100 m-auto ${selected ? 'border border-success border-3' : ''}`} src={imgUrl}></img><div style={{ backdropFilter: 'blur(0.5em)' }} className='position-absolute w-100 h-100'></div></div>
  }

  useEffect(() => {

    setActiveIndex(mapState.currentImage);
   

  }, [mapState.currentImage]);

  const [animationPlaying, setAnimationPlaying] = useState(false);

  if (
    stream ? !animationPlaying && !mapState.fetching && items :
      !animationPlaying && !mapState.fetching && items && (items.filter(item => !item.additional).length === mapState.image_num)) //&& (imgLoadedCount === items.length)

    return <div className='position-relative'>

      <div style={{ zIndex: 100, cursor: 'pointer' }} className='position-absolute end-0 top-0 m-2 bg-white rounded-2 opacity-75'>
        {
          mapState.image_num > 1 && (isGrid ? <IoMdAlbums onClick={() => { setIsGrid(false); setSettings({ settings_display_grid: false }) }} size={30} /> : <BsGridFill onClick={() => { setIsGrid(true); setSettings({ settings_display_grid: true }) }} size={30} />)
        }
      </div>
      {!isGrid ?

        <Slides  item={items.filter(item => !item.additional)[activeIndex]} setItems={setItems} itemsNum={mapState.image_num} currentImage={mapState.currentImage + 1} next={() => next(stream)} previous={() => previous(stream)} ></Slides>
        :
        <div class="container">
          <div class="row row-cols-2">

            {items.filter(item => !item.additional).map(item => <GridImage id={item.id} imgUrl={item.full}></GridImage>)}

          </div>

        </div>
      }
      {
        items.filter(item => item.additional).length ? <Slides item={items.filter(item => item.additional)[0]} setItems={setItems} itemsNum={1} next={next} previous={previous} ></Slides>
          : null
      }
    </div>

  else return <div style={{ maxWidth: '1024px', aspectRatio: '4 / 3' }} className=' w-100 position-relative d-inline-block start-50 translate-middle-x'>
    <LoadingAnimation isFetching={mapState.fetching} setAnimationPlaying={setAnimationPlaying} />
  </div>

}

export default CarouselComponent;