
import React, { useState, useEffect } from 'react';

import { useSetGameMetaDataMutation, useGetGameMetaDataQuery, useStartGameMutation, useGetProfileQuery } from '../redux/apiSlice';
import { useNavigate } from 'react-router-dom';
import Prompt from './Prompt';

const ShowPromptOrStartGame = ({ mode, setNewGame }) => {

    const [setGameMode, { isLoading }] = useSetGameMetaDataMutation()
    const [startGame, { isLoading: isStarting }] = useStartGameMutation();
    const { data: { isActive: isActiveGame } = { isActive: undefined } } = useGetGameMetaDataQuery();
    const {
        data: settings
    } = useGetProfileQuery({data_type:'settings'}); //,{refetchOnMountOrArgChange: true,}

    const [promptVisible, setPromptVisible] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        if (isActiveGame && settings.settings_ask_before_new_game.value) setPromptVisible(true)
        else {
           if (mode != null) setGameMode({ mode }).then(() => startGame().then(() => { navigate('/game', { replace: true }); setNewGame(false) }))
           else { startGame().then(() => { navigate('/game', { replace: true }); setNewGame(false) }) }
        }
    }, [])

    return (
        <>
            <Prompt modal={promptVisible}
                action={() => setGameMode({ mode }).then(() => { startGame().then(() => { navigate('/game', { replace: true }); setPromptVisible(!promptVisible); setNewGame(false) })})}
                toggle={() => { setPromptVisible(!promptVisible); setNewGame(false) }} />
        </>
    )
}

export default ShowPromptOrStartGame