
import 'leaflet/dist/leaflet.css';
import { useParams } from 'react-router-dom';
import { useGetProfileQuery, useSetSettingsMutation } from '../../redux/apiSlice'
import {  Card, CardHeader, FormGroup, Input, Label, Spinner } from 'reactstrap';

const Settings = () => {
    const { userId } = useParams();

    const {
        data: settings, isSuccess: settingsLoaded, isFetching: settingsFetching
    } = useGetProfileQuery(userId !=null ? {user_id:userId,data_type:'settings'} : {data_type:'settings'}); //,{refetchOnMountOrArgChange: true,}

    const [setSettings, { }] = useSetSettingsMutation();

    if ( settingsFetching) {
        return <div className='w-100 h-100 d-flex justify-content-center align-items-center'> <Spinner size='lg'></Spinner></div>
    }

    else if ( settingsLoaded) return (
    
    <Card className='mt-5 shadow-sm'>
    <CardHeader>
        Настройки
    </CardHeader>

    {settings && Object.entries(settings).map(([name, setting]) =>
        <div className='p-3' >
            <FormGroup switch >
                <Input
                    style={{ cursor: 'pointer' }}
                    type="switch"
                    name={name}
                    checked={setting.value}
                    onClick={(e) => {
                        setSettings({ [e.target.name]: e.target.checked });
                    }}
                />
                <Label style={{ cursor: 'pointer' }} check> {setting.label} </Label>
            </FormGroup>
        </div>
    )}

</Card>
  )
}

export default Settings