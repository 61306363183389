import { useParams } from "react-router-dom";

import React, { useEffect, useState } from 'react'
import Check from './check.svg'
import Remove from './remove.svg'
import { useDispatch, useSelector } from 'react-redux';
import CarouselComponent from '../CarouselComponent';

import CurrentGame from './CurrentGame';
import MainComponent from './MainComponent';

import 'react-circular-progressbar/dist/styles.css';
import { useGetProfileQuery } from "../../redux/apiSlice";
import { setImages, setMarkers, setFetching, setCurrentGuess, setMetaData, setGameFeedback } from '../../redux/mapSliceStream';
import { SocketStream } from "./SocketStream";
import Timer from "./Timer";

import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
`;

const PulsingCircle = styled.div`

  width: 0.75em;
  height: 0.75em;
  flex-shrink: 0;
  background-color: green;
  border-radius: 50%;
  animation: ${pulse} 3s ease-in-out infinite;

  margin-right: 0.5em;

`;

const Stream = () => {

    
    const [socketData, setSocketData] = useState(null);
    const { userId } = useParams();

    const [time, setTime] = useState(null);
    const [icon, setIcon] = useState(null)

    const dispatch = useDispatch();
    const mapState = useSelector(state => state.mapStream)

    const isSending = useSelector(state => state.map.sending)

    const {
        data:userData,
    } = useGetProfileQuery({ user_id: userId, data_type: 'username' }); //,{refetchOnMountOrArgChange: true,}

    useEffect(() => {

        if (socketData) switch (socketData.type) {

            case 'meta': {

                dispatch(setMetaData(socketData.data))

                break
            };
            case 'status': {
                dispatch(setFetching(socketData.data))
            }
            case 'data': {
                //console.log(socketData)
                socketData.data?.images && dispatch(setImages(socketData.data));
                socketData.data?.markers && dispatch(setMarkers(socketData.data));
                /*
                setTimeout(() => {

                    dispatch(setFetching(false))
                }, 500);
                */
                break
            };

            case 'response': {

                if (socketData.data.res != null) {

                    //dispatch(setMarkers(socketData.data));

                    if (socketData.data.res === 'guessed') setIcon({ type: 'success', html: <div style={{ width: '2em', height: '2em', backgroundSize: 'contain', backgroundImage: `url(${Check})` }}></div> });
                    else if (socketData.data.res === 'failed') setIcon({ type: 'fail', html: <div style={{ width: '2em', height: '2em', backgroundSize: 'contain', backgroundImage: `url(${Remove})` }}></div> });

                    else setIcon(null)

                } else {
                    setIcon(null)
                }

                dispatch(setGameFeedback({ message: socketData.data.message, points: socketData.data.points }))
                //setPoints(socketData.data.points)
                //setMessage(socketData.data.message)

                break
            };

            case 'time': {
                setTime(socketData.data)
            }

        }

    }, [socketData]);


    return (

        <div className="d-flex flex-column">

            <div className="ms-auto fs-5 me-3 d-flex align-items-center" style={{ borderTopRightRadius: '1em', borderTopLeftRadius: '1em' }}>

                <PulsingCircle />

                <div>вы смотрите стрим <b>{userData?.username}</b></div> 
            </div>

            <div className="border border-success rounded-5 m-3 p-3">
                <SocketStream userId={userId} socketData={socketData} setSocketData={setSocketData} />

                <MainComponent
                    stream={true}
                    LeftColumn={
                        <>
                            {mapState.isActive && (mapState.mode === 'fast') ? <Timer time={time} fetching={mapState.fetching} connectionStatus='Open' /> : null}
                            <CarouselComponent stream={true} />
                        </>
                    }
                    RightColumn={
                        <>
                            <CurrentGame stream={true} icon={icon} isSending={isSending} />
                           
                        </>
                    }
                />
            </div>
        </div>
    )

}

export default Stream
