import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsFillTrophyFill } from 'react-icons/bs';
import styled from 'styled-components';

const GameIcon = styled.div`
  display: inline-block;
  background-color: gold;
  border-radius: 50%;
  padding: 5px;
  transition: transform 200ms, opacity 200ms;
  opacity: 0.5;
  position: fixed;
  right: 5vh;
  bottom: 10vh;
  width: 6em;
  height: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    opacity: 0.9;
  }
`;

const NoticeText = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2px;
  padding: 1px;
  background-color: white;
  color: black;
  border-radius: 5px;
`;

const AmountText = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 2px;
  padding: 1px;
  background-color: green;
  color: white;
  font-weight: bold;
  border-radius: 5px;
`;

const NewAchievementNotice = ({ amount, setNewAchievements }) => {
  const navigate = useNavigate();

  return (
    <GameIcon onClick={() => { navigate('/profile/achievements', { replace: true }); setNewAchievements(false); }}>
      <BsFillTrophyFill color='white' size={69} />
      <NoticeText>обновлены достижения</NoticeText>
      <AmountText>+{amount}</AmountText>
    </GameIcon>
  );
};

export default NewAchievementNotice;

