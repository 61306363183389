import { useGetGameMetaDataQuery, useImageFeedbackMutation, useStartGameMutation } from "../../redux/apiSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Button,Tooltip } from "reactstrap";
import { useNavigate } from 'react-router-dom';
const SkipButton = () => {
    const navigate = useNavigate();

    const currentImage = useSelector(state => state.map.currentImage)
    const [tooltipOpen, setTooltipOpen] = useState(false)
   
    const [startGame, { isLoading: isStarting }] = useStartGameMutation();

    const { data: { isActive: isActiveGame, mode: currentGameMode } = { isActive: undefined, mode: undefined } } = useGetGameMetaDataQuery();
    const [skipImage, { isLoading: isSending }] = useImageFeedbackMutation();

    return (
       
           <div style={{ display: currentGameMode !== 'fast' ? 'block' : 'none' }} >

            <Tooltip
                 
                 isOpen={tooltipOpen}
                 toggle={ () => setTooltipOpen(tooltipOpen => !tooltipOpen) }
                 placement="bottom"
                 target="skip-button"
             >
                 пропустите фото, если оно размытое, темное, снято внутри здания или неинформативно (например, видно только небо) 
             </Tooltip>
                <Button id="skip-button" disabled={isSending || !isActiveGame} className='mt-2 rounded-4 text-uppercase' block  color='primary' onClick={() => {

                    skipImage({ currentImage, action:'skip' }).unwrap().catch((err) => err && navigate('/error')).then( ()=> startGame() );

                }}>пропустить фото</Button>
            </div>
       
    )
}

export default SkipButton