import React from 'react'

const ErrorPage = () => {
  return (
    <body className='d-flex' style={{background:'linear-gradient(124deg, rgba(75,75,255,1) 0%, rgba(21,21,255,1) 100%)'}}>
    <div className='m-auto text-white'>
        <h1>Ой...</h1>
        <div>
            <p>
                Что-то пошло не так. Попробуйте <a href='/' className='text-white fw-bold'>вернуться</a> на главную страницу
            </p>
            
        </div>
        
    </div>
    </body>
  )
}

export default ErrorPage