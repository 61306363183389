import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Modal, ModalHeader, ModalBody, FormGroup, Label, Input } from 'reactstrap';
import {  useSetSettingsMutation } from '../redux/apiSlice';

const Prompt = ({ modal, toggle, action }) => {

    const [setSettings, { }] = useSetSettingsMutation();

    const [checked,setChecked] = useState(false)
    useEffect( ()=> {
        if(checked) {
            setSettings({ settings_ask_before_new_game: false }).then( action )
        }
    } , [checked] )
    return (
        <div>

            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Новая игра</ModalHeader>
                <ModalBody>

                    
                    Вы уверены, что хотите начать новую игру? Текущая игра не будет сохранена.
                   
                   
                    <ButtonToolbar className="my-2">

                        <Button color="primary" onClick={action}
                            className="me-2" >
                            Да!
                        </Button>

                        <Button  className="me-2" onClick={() => toggle()} >
                            Отмена
                        </Button>

                    </ButtonToolbar>
                    <FormGroup
                        check
                        
                        onClick={ () => setChecked( checked => !checked ) }
                    >
                        <Input checked={checked} type="checkbox" />
                        <Label style={{cursor:'pointer'}} check>
                            Больше не спрашивать
                        </Label>
                    </FormGroup>
                </ModalBody>

            </Modal>
        </div>
    );
}

export default Prompt;