import { useEffect } from "react";
import { useGetStreamingListQuery } from "../redux/apiSlice"
import { NavLink } from 'react-router-dom';
import { Button, Card, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
const StreamingList = () => {

  const { data, isSuccess, isFetching, refetch } = useGetStreamingListQuery()
  const navigate = useNavigate();

  if (isSuccess)
    return (
      <div className="w-100 w-lg-75 m-auto d-flex flex-column align-items-center justify-content-center ">
       
        <div className='fs-2 text-end text-uppercase comfortaa m-2 w-100' > сейчас стримят </div>
        { data?.length !== 0 ?
        <Card className='shadow-sm w-100'>
          <Table hover >

            <tbody>
              {
                data.map(user =>
                  <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`/stream/${user.id}`, { replace: true })}>
                    <td className='text-start'>
                      <div className='text-light d-inline-block rounded-3 p-3' style={{ background: 'linear-gradient( to right, #47a0ff,rgb(30, 139, 196) ) ' }}> {user.username} </div>
                    </td>
                    <td className='text-end'>
                      <div className='text-dark d-inline-block rounded-3 p-3' > {user.game_mode} </div>
                    </td>
                  </tr>

                )
              }

            </tbody>
          </Table>
        </Card> : 
        <p className="fs-4">пока никого...😥</p>
            }
  <Button className="m-2" onClick={refetch}>обновить</Button>
      </div>
    )
}

export default StreamingList