
import EarthPic from './earth_minified.svg'

import MoonSvg from './moon_minified.svg'
import Marker from './marker_minified.svg'
import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const EarthContainer = styled.div`
  flex-basis: 15em;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  background-image: url("${EarthPic}");
  background-size: cover;
  position: relative;
  transform-style: preserve-3d;
  border-radius: 50%;
`;

const stages = ['moveBehindFromCenter', 'moveFrontTowardsCenter', 'moveFrontFromCenter', 'moveBehindTowardsCenter',]

const moveAnimation = (phi, stage) => {
  switch (stage) {
    case 'moveFrontFromCenter': return keyframes`
    from {
      transform : translateY(40%) translateX(40%) ;   
    }
    to {
      transform : translateY(${40 + 70 * Math.sin(phi)}%) translateX(${40 + 70 * Math.cos(phi)}%) ;
    }`;
    case 'moveFrontTowardsCenter': return keyframes`
    from {
      transform : translateY(${40 - 70 * Math.sin(phi)}%) translateX(${40 - 70 * Math.cos(phi)}%) ;   
    }
    to {
      transform : translateY(40%) translateX(40%) ;
    }`;

    case 'moveBehindTowardsCenter': return keyframes`
    from {
      transform : translateY(${40 + 70 * Math.sin(phi)}%) translateX(${40 + 70 * Math.cos(phi)}%) translateZ(-1px);
    }
    to {
      transform : translateY(40%) translateX(40%) translateZ(-1px)
    }`;
    case 'moveBehindFromCenter': return keyframes`
    from {
      transform : translateY(40%) translateX(40%) translateZ(-1px)
    }
    to {
      transform : translateY(${40 - 70 * Math.sin(phi)}%) translateX(${40 - 70 * Math.cos(phi)}%) translateZ(-1px); 
    }`;
  }
}

const growAnimation = keyframes`
  0% { transform: scale(1) };
  50% { transform: scale(2) };
  100% { transform: scale(1) };
  `
const decreaseAnimation = keyframes`
  0% { transform: scale(1) };
  50% { transform: scale(0.5) };
  100% { transform: scale(1) };
  `

const Moon = styled.div`

    width:20%;
    height:20%;
    border-radius:50%;
    position:absolute;
    background-image: url("${MoonSvg}");
    background-size:cover;
    animation: ${props => props.moonGrowing ? growAnimation : decreaseAnimation} 2s ease-in-out infinite;

`;

const Wrapper = styled.div`

    animation: ${props => moveAnimation(props.phi, stages[props.currentStage])} 1s ${props => props.currentStage % 2 == 1 ? 'ease-in' : 'ease-out'} forwards ;
    position: relative;
    width: 100%;
    height: 100%;
 
    opacity:1;
`;
const BouncingMarker = styled.div`

  @keyframes bounce {
    0% { transform: translateY(0px)};
    50% { transform: translateY(10px)};
    100% { transform: translateY(0px)}
  }
  background-image: url('${Marker}');
  background-size: contain;
  background-repeat: no-repeat;
 
  width: 7em;
  height: 7em;
  position: absolute;
  background-position: center;
  
  top: -30%;
  animation: bounce 2s linear infinite`;


const Earth = () => {
  const [moonGrowing, setMoonGrowing] = useState(true)

  const [phi, setPhi] = useState(Math.PI / 4);
  const [currentStage, setCurrentStage] = useState(1)

  return <EarthContainer>

    <BouncingMarker ></BouncingMarker>
    <Wrapper onAnimationEnd={() => { if (currentStage === 2) setMoonGrowing(false); if (currentStage === 0) setMoonGrowing(true); setCurrentStage(currentStage => (currentStage + 1) % stages.length); if (currentStage === 3) setPhi(phi => (phi + 0.4) % (2 * Math.PI)) }} phi={phi} currentStage={currentStage}>

      <Moon moonGrowing={moonGrowing}></Moon>
    </Wrapper>

  </EarthContainer>
}

export default Earth