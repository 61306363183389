import { Modal, ModalBody, ModalHeader, Form, FormGroup, FormFeedback, Input, Label, Button, Spinner } from "reactstrap"
import { useState } from "react"
import { useSendFeedbackMutation } from "../redux/apiSlice"
const Feedback = ({ open, toggle }) => {

    const fields = [
        {
            id: "NameField",
            name: "name",
            label: "Ваше имя",
            type: "text",
            validators: []
        },
        {
            id: "EmailField",
            name: "email",
            label: "Адрес электронной почты",
            type: "email",
            validators: [(email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) ? { valid: true } : { valid: false, message: 'Пожалуйста, введите корректный адрес электронной потчы' }]
        },
        {
            id: "MessageField",
            name: "message",
            label: "Сообщение",
            type: "textarea",
            validators: [(text) => text ? { valid: true } : { valid: false, message: 'Это обязательное поле' }]

        }
    ]

    const [sendFeedback, { isSuccess, isLoading }] = useSendFeedbackMutation()

    const [formData, setFormData] = useState(Object.assign(...fields.map(field => field.name).map(k => ({ [k]: null }))));
    const [currentError, setCurrentError] = useState({})

    const onChange = e => {

        setCurrentError({})
        setFormData({ ...formData, [e.target.name]: e.target.value });

    }

    const onSubmit = async (e) => {

        e.preventDefault();
        let errors = fields.map(field => {
            let validation_res = field.validators.map(validator => validator(formData[field.name])).filter(i => !i.valid);

            if (validation_res.length) return { [field.name]: validation_res.map(res => res.message) }
        }
        ).filter(i => i)

        if (errors.length) {
            setCurrentError({ ...currentError, ...Object.assign(...errors) })
        }
        if (!errors.length) await sendFeedback(formData).unwrap()
            .then(res => setTimeout(() => toggle(), 1000))
            .catch(error => {

                setCurrentError({ message:['Что-то пошло не так... Не удалось отправить сообщение. Пожалуйста, попробуйте повторить попытку позднее'] })
            })

    }

    return (
        <div>

            <Modal isOpen={open} toggle={toggle} >
                <ModalHeader toggle={toggle}>Отправить сообщение</ModalHeader>
                <ModalBody>

                    <Form onSubmit={e => onSubmit(e)}>

                        {fields.map(field => <FormGroup floating>

                            <Input invalid={currentError[field.name]} valid={isSuccess} onChange={e => onChange(e)}
                                id={field.id}
                                name={field.name}
                                type={field.type}
                            />

                            <Label for={field.id}>
                                {field.label}
                            </Label>
                            {
                                currentError[field.name] ?
                                    <FormFeedback tooltip>
                                        {currentError[field.name].map(msg => <div>{msg}</div>)}
                                    </FormFeedback> : null
                            }
                        </FormGroup>)}


                        <Button disabled={ isLoading } type='submit'>
                         { !isLoading ? <span>Отправить</span> : <Spinner size='sm' /> }
                        </Button>
                    </Form>
                </ModalBody>

            </Modal>
        </div>
    )
}

export default Feedback