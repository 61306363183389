import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import { marker } from "leaflet";

export const mapSlice = createSlice({
    name: "map",
    initialState: {

        hasProfile: false,

        images: [],
        markersPrev: [],
        markers: [],
        marker_history: [],
        circles: [],

        mode: 'normal',
        currentImage: 0,
        // use for fast game mode:
        currentGuess: null,
        gameFeedback: { message: null, points: null },

        fetching: false,
        sending: false

    },

    reducers: {
        setHasProfile: (state, action) => {

            state.hasProfile = action.payload

        },
        setGameFeedback: (state, action) => {

            state.gameFeedback = action.payload

        },

        setCurrentGuess: (state, action) => {

            state.currentGuess = action.payload

        },

        setFetching: (state, action) => {

            state.fetching = action.payload

        },
        setSending: (state, action) => {

            state.sending = action.payload

        },
        refresh: (state, action) => {
            state.markersPrev = state.markers;
        },

        setImages: (state, action) => {
            state.images = action.payload.images
        },

        setMarkers: (state, action) => {

            if (!action.globalState.api.queries['getGameMetaData(undefined)'].data.isActive) return;

            state.markersPrev = state.markers;
            state.markers = action.payload.markers;


            if (action.payload.circles != null) {
                state.circles = action.payload.circles;
            }
            /*******************/
           
            if ((action.globalState.api.queries['getProfile({"data_type":"settings"})'].data.settings_show_send_button.value === false) || (state.mode == 'warmer')) {

                if (state.mode == 'arbitrary' || state.mode == 'warmer') {
                    action.asyncDispatch(apiSlice.endpoints.sendGuess.initiate({ answer: state.markers, show_right_answer: false }, { fixedCacheKey: 'shared-send-guess' }))
                }

            }
            /*******************/
        }
        ,

        updateMarker: (state, action) => {

            if (!action.globalState.api.queries['getGameMetaData(undefined)'].data.isActive) return;
           
            state.markersPrev = state.markers;
            state.markers = state.markers.map((marker, id) => {
                if (marker?.right != undefined) return marker
                if (id === action.payload) return { ...marker, badge: state.images[state.currentImage].id, imgIdx: state.currentImage }
                else if (marker.badge === state.images[state.currentImage].id) return { ...marker, badge: null, imgIdx: null }
                else return marker

            })

            /*******************/
            if ( (action.globalState.api.queries['getProfile({"data_type":"settings"})'].data.settings_show_send_button.value === false) || (state.mode === 'warmer') ) {

                switch (state.mode) {
                    case 'many': case 'photo': { break };
                    case 'fast': { state.currentGuess = { type: 'guess', data: { answer: state.markers, show_right_answer: false } }; break };
                    default: action.asyncDispatch(apiSlice.endpoints.sendGuess.initiate({ answer: state.markers, show_right_answer: false }, { fixedCacheKey: 'shared-send-guess' }))
                }

            }
            /*******************/

        },
        setCurrentImage: (state, action) => {

            //const length = action.globalState.api.queries['getGameData(undefined)'].data.images.filter(image => !image.additional).length//= state.mode === 'many' ? 4 : 1 //////////////////////////////
            const idx = action.payload
            
            state.currentImage = idx >= 0 ? idx % state.image_num : state.image_num + idx % state.image_num;

            action.asyncDispatch(refresh())
        },

        nextImage: (state, action) => {

            //const length = action.globalState.api.queries['getGameData(undefined)'].data.images.filter(image => !image.additional).length//= state.mode === 'many' ? 4 : 1 //////////////////////////////

            if (state.image_num > 1) {
                while (state.markers.find(marker => marker.badge === state.currentImage)) {
                    if (state.markers.filter(marker => marker.badge == null).length === 0) break;
                    state.currentImage = (state.currentImage + 1) % state.image_num

                }
            }

        }

    },
    extraReducers: (builder) => {

        builder.addMatcher(apiSlice.endpoints.getGameMetaData.matchFulfilled, (state, action) => {

            state.mode = action.payload.mode;
            state.image_num = action.payload.image_num;

        });

        builder.addMatcher(apiSlice.endpoints.startGame.matchFulfilled, (state, action) => {

            state.gameFeedback = { message: null, points: null, res:null }


        });


        builder.addMatcher(apiSlice.endpoints.getGameData.matchFulfilled, (state, action) => {

            state.fetching = false;

            state.images = action.payload.images;

            state.markers = action.payload.markers.map( marker => ({ ...marker, imgIdx: state.images.findIndex(image => image.id === marker.badge) }) );
            state.marker_history = action.payload.marker_history;
            state.markersPrev = state.markers;
            
            state.gameFeedback = {...state.gameFeedback, message: action.payload.message, points: null, res:null }

            state.circles = action.payload.circles;
            state.currentImage = 0
        });

        builder.addMatcher(apiSlice.endpoints.getGameData.matchPending, (state, action) => {
           state.fetching = true;
        });

        builder.addMatcher(apiSlice.endpoints.logout.matchFulfilled, (state, action) => {
            state.hasProfile = false;
         });

        builder.addMatcher(apiSlice.endpoints.sendGuess.matchFulfilled, (state, action) => {

            state.gameFeedback = action.payload;
            if (action.payload.images) {
                state.images = action.payload.images
            }

            if (action.payload.markers) {

                state.circles = action.payload.circles
                
                state.markersPrev = state.markers;
                state.markers = action.payload.markers;//state.markers.map(marker => { if (marker?.badge in action.payload.markers) { return { ...marker, right: action.payload.markers[marker?.badge].includes(true) } } else return marker })
                
                state.marker_history = action.payload.marker_history;

            }
        })

    }
})

export default mapSlice.reducer;
export const { setMarkers, setHasProfile, setImages, updateMarker, setCurrentImage, nextImage, refresh, setFetching, setCurrentGuess, setSending, setGameFeedback } = mapSlice.actions