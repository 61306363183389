import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import Cookies from 'js-cookie';

const apiSlice = createApi(
    {
        reducerPath: 'api',
        baseQuery: fetchBaseQuery({
            baseUrl: `${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/api`,
            credentials: "include",
            mode: 'cors'
        }),
        tagTypes: ['Profile', 'GameData', 'GameMetaData', 'Hint', 'Rating', 'isLogged'],
        endpoints: builder => ({
            getCSRF: builder.query({
                query: () => `http://${process.env.REACT_APP_API_URL}/`
            }),
            startGame: builder.mutation({
                query: () => ({
                    url: 'game/start_game',
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken'),

                    }

                }),

                //invalidatesTags: (res) => (res.mode === 'fast' ? ['ActiveGame', 'Hint'] : ['GameData', 'ActiveGame', 'Hint'])
                invalidatesTags: (res) => { if (res.mode === 'fast') return ['GameMetaData', 'Hint']; else return ['GameMetaData', 'Hint', 'GameData'] }
            }),

            stopGame: builder.mutation({
                query: () => ({
                    url: 'game/stop_game',
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    }

                }),
                invalidatesTags: ['GameMetaData']
            }),

            getGameData: builder.query({
                query: () => 'game/game',
                providesTags: ['GameData'],

            }),


            sendGuess: builder.mutation({
                query: guess => ({
                    url: 'game/game',
                    method: 'POST',
                    // Include the entire post object as the body of the request
                    body: guess,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }

                }),
                invalidatesTags: (result, error, arg) => (!result.is_active ? [{type:'Profile',id:'achievements_info'},{type:'Profile',id:'coins'},{type:'Profile',id:'stats'},{type:'Profile',id:'rating'}, 'GameMetaData'] : [ 'GameMetaData']),
                

            }),

            getGameMetaData: builder.query({
                query: () => 'game/game_meta',
                providesTags: ['GameMetaData']
            }),

            setGameMetaData: builder.mutation({
                query: data => ({

                    url: 'game/game_meta',
                    method: 'POST',
                    body: data,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }

                }),

                //invalidatesTags: ['GameMetaData'],

            }),

            imageFeedback: builder.mutation({
                query: data => ({

                    url: 'game/image_feedback',
                    method: 'POST',
                    body: data,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }

                }),

                invalidatesTags: [{type:'Profile',id:'liked_info'}],

            }),

           
            setTheme: builder.mutation({
                query: theme_title => ({
                    url: 'game/theme',
                    method: 'POST',
                    // Include the entire post object as the body of the request
                    body: theme_title,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }

                }),
                invalidatesTags: ['Theme', {type:'Profile',id:'settings'},{type:'Profile',id:'theme'} ],
            }),
            getThemes: builder.query({
                query: () => 'game/themes',
                transformResponse: (response) => {
                    // Filter active and non-active objects from the response data
                    const themes = response || [];

                    const [activeTheme] = themes.filter((theme) => theme.active);
                    const [currentTheme] = themes.filter((theme) => theme.is_current);
                    const archivedThemes = themes.filter((theme) => !theme.active);

                    // Return the filtered data
                    return { activeTheme, archivedThemes,currentTheme };
                },
                providesTags: ['Theme']
            }),
            getHint: builder.mutation({
                query: hint_type => ({
                    url: 'game/hint',
                    method: 'POST',
                    body: { hint_type },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }),
                invalidatesTags: ['Profile', 'GameData', 'Hint'],
            }),

            getHintStatus: builder.query({
                query: () => 'game/hint',
                providesTags: ['Hint']
            }),

            getGameInfo: builder.query({
                query: () => 'game/game_info'
            }),

            getProfile: builder.query({
                query: (params) => ({
                    url: params?.user_id && params?.data_type
                        ? `game/profile&id=${params.user_id}&data=${params.data_type}`
                        : params?.user_id ?
                            `game/profile&id=${params.user_id}` :
                            params?.data_type ?
                                `game/profile&data=${params.data_type}`
                                : 'game/profile',
                }),
                providesTags: (result, error, params) =>
                    params?.user_id && params?.data_type
                        ? []
                        : params?.user_id ?
                            [] :
                            params?.data_type ?
                                [{ type: 'Profile', id: params.data_type }]
                                : ['Profile']

               
            }),
            updateStream: builder.mutation({
                query: data => ({
                    url: 'game/stream',
                    method: 'POST',
                    body: data,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                })
            }),
            getStreamingList: builder.query({
                query: () => `game/stream`,

            }),

            setSettings: builder.mutation({
                query: settings => ({
                    url: 'accounts/settings',
                    method: 'POST',
                    body: settings,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }),
                invalidatesTags: [{type:'Profile',id:'settings'},{type:'Profile',id:'theme'}],
            }),
            login: builder.mutation({
                query: credentials => ({
                    url: 'accounts/login',
                    method: 'POST',

                    body: credentials,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }),
                invalidatesTags: ['isLogged', 'Profile'],
            }),

            logout: builder.mutation({
                query: () => ({
                    url: 'accounts/logout',
                    method: 'POST',

                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }),
                invalidatesTags: ['isLogged'],
            }),
            signup: builder.mutation({
                query: credentials => ({
                    url: 'accounts/signup',
                    method: 'POST',

                    body: credentials,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }),
                invalidatesTags: ['isLogged'],
            }),

            checkIfLogged: builder.query({
                query: () => 'accounts/is_logged',
                providesTags: ['isLogged']
            }),

            sendFeedback: builder.mutation({
                query: credentials => ({
                    url: 'accounts/feedback',
                    method: 'POST',

                    body: credentials,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                })
            }),

        })
    }

)

export const {
    useSendGuessMutation,
    useStartGameMutation,
    useStopGameMutation,
    useGetGameDataQuery,
    useLazyGetGameDataQuery,
    useGetProfileQuery,
    useLazyGetProfileQuery,
    useUpdateStreamMutation,
    useGetStreamingListQuery,
    useGetHintMutation,
    useGetHintStatusQuery,
    useGetGameInfoQuery,
    //useLazyGetSettingsQuery,
    useSetSettingsMutation,
    useGetGameMetaDataQuery,
    useSetGameMetaDataMutation,
    useLoginMutation,
    useCheckIfLoggedQuery,
    useLogoutMutation,
    useSignupMutation,
    useGetCSRFQuery,
    useSendFeedbackMutation,
    useImageFeedbackMutation,
    useGetThemesQuery,
    useSetThemeMutation
} = apiSlice

export { apiSlice }

