import logoImg from './logo_white_letters_minified.svg'
import Feedback from './Feedback'
import { NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap'

import { useState } from 'react'

const BottomBar = () => {
    const [open,setOpen] = useState(false)
    return (
        <>
        <Feedback open={open} toggle={ ()=> setOpen(open=> !open) } />
        <footer  className='mt-5 bg-dark'>

            <Row className='align-items-center p-2'
                sm="2"
                xs="1"
            >
                <Col className="text-white-50 ps-5 pt-4 pe-5 text-start ">
                    Представленные на сайте изображения расположены на сайте Mapillary.com в открытом доступе.
                    Любые жалобы и предложения могут быть адресованы администрации сайта через форму обратной связи.
                    <br></br><a href="https://www.freepik.com/free-vector/earth-globe-world-map-dots-global-geography-dotted-pattern_13031912.htm">Image by macrovector</a> on Freepik
                    <br></br><a href="https://www.vecteezy.com/free-vector/city-skyline">City Skyline Vectors by Vecteezy</a>
                    <br></br><a href="https://www.flaticon.com/free-icons/goblet" title="goblet icons">Goblet icons created by Smashicons - Flaticon</a>
                </Col>
                <Col >
                    <Navbar className='fs-5 text-start '>

                        <NavbarBrand href="/" style={{ width: '8em', backgroundImage: `url(${logoImg})` }}>

                        </NavbarBrand>

                        <Nav vertical>

                            <NavItem>

                                <NavLink onClick={ ()=>setOpen(true) }>
                                    обратная связь
                                </NavLink>

                            </NavItem>

                        </Nav>

                    </Navbar>
                </Col>

            </Row>

        </footer>
        </>
    )
}

export default BottomBar
