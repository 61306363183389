import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { useCheckIfLoggedQuery, useLoginMutation } from '../redux/apiSlice';

const Login = ({ showSignup, setShowSignup, toggle }) => {

    const fields = [
        {
            id: "LoginField",
            name: "username",
            label: "Логин",
            type: "text",
            validators: [(text) => text ? { valid: true } : { valid: false, message: 'Это обязательное поле' }]
        },
        {
            id: "PasswordField",
            name: "password",
            label: "Пароль",
            type: "password",
            validators: [(text) => text ? { valid: true } : { valid: false, message: 'Это обязательное поле' }]
        },
    ]


    const [login, { isSuccess }] = useLoginMutation()

    const [formData, setFormData] = useState(Object.assign(...fields.map(field => field.name).map(k => ({ [k]: null }))));
    const [currentError, setCurrentError] = useState({})

    const onChange = e => {
        setCurrentError({})
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        let errors = fields.map(field => {
            let validation_res = field.validators.map(validator => validator(formData[field.name])).filter(i => !i.valid);

            if (validation_res.length) return { [field.name]: validation_res.map(res => res.message) }
        }
        ).filter(i => i)

        if (errors.length) {
            setCurrentError({ ...currentError, ...Object.assign(...errors) })
        }
        if (!errors.length) await login(formData).unwrap()
            .then(res => setTimeout(() => toggle(), 1000))
            .catch(error => {
             
                setCurrentError({
                    ...currentError, ...Object.entries(JSON.parse(error.data.detail)).reduce((p, [k, v]) => ({ ...p, [k]: v.map(singleErr => singleErr.message) }), {})
                });

            })

    }

    useEffect(() => {
        if (currentError['__all__']) {
            let keys = Object.keys(currentError).filter(key => key !== '__all__')
            setCurrentError(currentError => ({ ...Object.assign(keys.map(key => ({ [key]: currentError[key] }))), [fields.at(-1).name]: currentError['__all__'] }))
        }
    }, [currentError])
    return (

        <Form onSubmit={e => onSubmit(e)}>

            {fields.map(field => <FormGroup floating>

                <Input invalid={!!currentError[field.name]?.length} valid={isSuccess} onChange={e => onChange(e)}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                />

                <Label for={field.id}>
                    {field.label}
                </Label>
                {
                    currentError[field.name] ?
                        <FormFeedback tooltip>
                            {currentError[field.name].map(msg => <div>{msg}</div>)}
                        </FormFeedback> : null
                }

            </FormGroup>)}

            <Button type='submit'>
                Войти
            </Button>
            <span className='ms-2'>Еще нет аккаунта? <a href="#" onClick={() => setShowSignup(!showSignup)} class="link-primary"> Зарегистрируйтесь! </a></span>

        </Form>
    );
}

export default Login;