
import { Badge, UncontrolledTooltip, Collapse, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap'
import { useGetGameInfoQuery, useCheckIfLoggedQuery, useGetProfileQuery } from '../redux/apiSlice';
import ShowPromptOrStartGame from './ShowPromptOrStartGame';
import Earth from './Earth';
import EarthScandinavia from './EarthScandinavia';
import EarthItaly from './EarthItaly';
import PixelMap from './world-map-5.svg'
import { useEffect, useState } from 'react';
import Register from './Register';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LogoLink = styled.span`

  display: inline-block;
 
  background: rgb(73,162,255,0.75);
  padding: 0.5em;
  border-radius: 1em;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    
    transform:  translate(0,-0.5em);

  }
 
`;

const About = ({ firstVisit }) => {

  const {
    data: currentTheme, isSuccess: currentThemeLoaded, isFetching: currentThemeFetching
  } = useGetProfileQuery({ data_type: 'theme' }); //,{refetchOnMountOrArgChange: true,}

  const {
    data: settings, isSuccess: settingsLoaded, isFetching: settingsFetching
  } = useGetProfileQuery({ data_type: 'settings' }); //,{refetchOnMountOrArgChange: true,}

  const { data: gameInfo, isSuccess: gameInfoLoaded, isFetching: gameInfoFetching } = useGetGameInfoQuery()
  const { data: isLogged } = useCheckIfLoggedQuery()

  const gradientStartColor = [73, 162, 255, 1]
  const gradientEndColor = [30, 139, 196, 1]; //[75, 75, 255, 1]
  const [gameInfoOpen, setGameInfoOpen] = useState(Array(10).fill(false))

  const [newGame, setNewGame] = useState(false)
  const [newMode, setNewMode] = useState(null)

  const currentGameMode = useSelector(state => state.map.mode )

  const earthes = { standard: <Earth />, 'Скандинавия': <EarthScandinavia />, 'Италия':<EarthItaly /> }
  const navigate = useNavigate();

  const mode_names_reversed = Object.fromEntries(Object.entries(JSON.parse(process.env.REACT_APP_MODE_NAMES)).map(a => a.reverse()))

  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (firstVisit) navigate('/game', { replace: true })
  }, [])

  if (gameInfoFetching || currentThemeFetching) {
    return <div className='w-100 h-100 d-flex justify-content-center align-items-center'> <Spinner size='lg'></Spinner></div>
  }
  else if (gameInfoLoaded && currentThemeLoaded)
    return (
      <>

        {newGame ? <ShowPromptOrStartGame mode={newMode != null ? newMode : currentGameMode } setNewGame={setNewGame} /> : null}

        <Modal isOpen={modal} toggle={() => setModal(modal => !modal)} >
          <ModalHeader toggle={() => setModal(modal => !modal)}>Регистрация</ModalHeader>
          <ModalBody>
            <Register toggle={() => setModal(modal => !modal)} />
          </ModalBody>
        </Modal>


        <div className=' position-absolute w-100  d-flex flex-column flex-lg-row justify-content-center align-items-center ' style={{ backgroundColor: '#1E8BC4', background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url(${PixelMap})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: "35em" }}>
          {!isLogged?.isAuthenticated ?
            <div style={{ zIndex: 200 }} onClick={() => setModal(modal => !modal)} className='m-3 text-light fs-4 text-start comfortaa ' > <LogoLink>зарегистрироваться</LogoLink></div>
            :
            <div style={{ zIndex: 200 }} onClick={() => setNewGame(true)} className='m-3 text-light fs-4 text-start comfortaa ' ><LogoLink>играть</LogoLink></div>
          }
          { currentTheme ? earthes[currentTheme] : earthes.standard}
          {!isLogged?.isAuthenticated ?
            <div style={{ zIndex: 200 }} onClick={() => setNewGame(true)} className='m-3 text-light fs-4 text-start comfortaa ' ><LogoLink>играть без регистрации</LogoLink></div>
            :
            <div style={{ zIndex: 200 }} onClick={() => navigate('/profile', { replace: true })} className='m-3 text-light fs-4 text-start comfortaa ' ><LogoLink>перейти в профиль</LogoLink></div>
          }
        </div>

        <div className='position-relative' style={{ height: "28em" }}> </div>

        <div className='position-relative mt-5 mb-3 w-100 d-flex m-auto flex-column  align-items-center justify-content-center'>


          <div style={{ background: 'linear-gradient(0deg, rgb(30, 139, 196, 1) 0%, rgba(73, 162, 255, 1) 100%)' }} className='fs-5 rounded-3 text-white p-3 w-100 w-lg-75 m-auto'>
            <div className='text-white fs-3 comfortaa text-uppercase text-start'>что это?</div>
            <hr></hr>
            Это увлекательная географическая игра, в которой вам предстоит проверить свои знания географии, смекалку и удачу, угадывая, в какой точке мира была сделана очередная случайная фотография.
            Может, это вершина Эвереста? Улицы Парижа? Или вообще задний двор вашего соседа? Кто знает…
            Всё может оказаться не тем, чем кажется на первый взгляд. Именно поэтому угадывать так интересно!
            Так что пакуйте свои виртуальные чемоданы и готовьтесь к безумному путешествию по всему земному шару!

          </div>

          <div style={{ background: 'linear-gradient(0deg, rgb(30, 139, 196, 1) 0%, rgba(73, 162, 255, 1) 100%)' }} className='fs-5 mt-3 rounded-3 text-white p-3 w-100 w-lg-75 m-auto'>
            <div className='text-white fs-3 comfortaa text-uppercase text-end'>как играть?</div>
            <hr></hr>
            Ознакомьтесь с описаниями игр ниже (вы можете прочитать описание, кликнув на название) и выберите то, что вам по душе. Вы также можете просто кликнуть по надписи <b>"играть без регистрации"</b>, либо сперва <b>зарегистрироваться</b> (это позволит хранить статистику ваших игр неограниченно долго).
            В разделе <b>"мой профиль"</b> вы найдете настройки, которые помогут сделать игру максимально удобной именно для вас.

          </div>


        </div>

        {
          gameInfo.res.map((info, indexGame) =>

            <div className='w-md-75 w-100 m-auto'>

              <div onClick={() => setGameInfoOpen(gameInfoOpen.map((val, ind) => ind === indexGame ? !val : val))} className='w-100 me-2 d-flex align-items-center  p-3 text-light' style={{ borderTopLeftRadius: indexGame === 0 ? '2em' : 0, borderTopRightRadius: indexGame === 0 ? '2em' : 0, borderBottomLeftRadius: indexGame === gameInfo.res.length - 1 ? '2em' : 0, borderBottomRightRadius: indexGame === gameInfo.res.length - 1 ? '2em' : 0, background: `linear-gradient(180deg, rgba( ${gradientStartColor.map((color, i) => (color + (gradientEndColor[i] - color) / gameInfo.res.length * indexGame)).join()} ) 0%, rgba(${gradientStartColor.map((color, i) => (color + (gradientEndColor[i] - color) / gameInfo.res.length * (indexGame + 1))).join()}) 100%)` }}>
                <div className='flex-grow-1' style={{ width: '8em', height: '8em', opacity: 0.5, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', filter: 'invert(1)', backgroundImage: `url(${info.icon})` }}>

                </div>
                <div className='fs-2 text-end text-uppercase comfortaa' >{info.name} </div>
              </div>
              <Collapse isOpen={gameInfoOpen[indexGame]}>
                <div className='w-100 d-flex flex-sm-row flex-column'>

                  <div className='p-3 flex-grow-1'>

                    <div className='fs-5'>
                      {info.description}
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='rounded-3 p-3 mt-3 d-inline-block' style={{ backgroundColor: `rgba(${gradientStartColor.map((color, i) => (color + (gradientEndColor[i] - color) / gameInfo.res.length * (indexGame + 1))).join()})` }}>

                        {info.hints.length !== 0 ? <>

                          <span className='text-light'>доступные подсказки</span>


                          {
                            info.hints.map((hint, indexHint) =>
                              <>
                                <Badge color='light' id={`hintTooltip${indexGame}${indexHint}`} pill className='ms-2 text-dark'>
                                  {hint.name}
                                </Badge>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={`hintTooltip${indexGame}${indexHint}`}>
                                  {hint.description}
                                </UncontrolledTooltip>
                              </>
                            )
                          }
                        </> :
                          <span className='text-light'>подсказок пока нет</span>
                        }
                      </div>
                        {
                      <div className='flex-shrink-1 ms-3'>
                        в <span className='fw-bold comfortaa'>{info.name}</span> играли <span className='p-1 rounded-5 text-white fw-bold' style={{ background: 'linear-gradient(0deg, rgb(30, 139, 196, 1) 0%, rgba(73, 162, 255, 1) 100%)' }}>{info.total_played.num}</span> <span>{info.total_played.word}</span>
                      </div>
                       }
                    </div>
                    <div style={{ background: 'linear-gradient(90deg, rgba(0,215,79,1) 0%, rgba(255,128,128,1) 100%)' }} className='d-flex mt-2 justify-content-between rounded-3 p-2'>
                      <div className='text-white fw-bold'>{info.maxPoints}</div>
                      <div className='text-white'>‹ максимум очков | минимум очков ›</div>

                      <div className='text-white fw-bold'>{info.minPoints}</div>
                    </div>

                  </div>


                </div>

                <div className='text-center mt-2 mb-2' >
                  <span onClick={() => {
                    setNewMode(mode_names_reversed[info.name]);

                    setNewGame(true);
                  }} className='comfortaa fs-4 fw-bold' style={{ cursor: 'pointer', color: `rgba(${gradientStartColor.map((color, i) => (color + (gradientEndColor[i] - color) / gameInfo.res.length * (indexGame + 1))).join()})` }}>{info.slogan}</span>
                </div>
              </Collapse>

            </div>

          )
        }


      </>
    )

}

export default About


