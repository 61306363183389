import React, { useCallback } from 'react'
import { MapContainer, TileLayer, Marker, FeatureGroup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import L from 'leaflet';
import * as ReactDOMServer from 'react-dom/server';
import { Pannellum } from "pannellum-react";

import { useState } from 'react';
import { useGetProfileQuery, useGetSettingsQuery, useSetSettingsMutation, useCheckIfLoggedQuery, useGetHistoryQuery } from '../../redux/apiSlice'
import { Alert, Pagination, PaginationItem, PaginationLink, Button, Spinner, UncontrolledCollapse, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { NavLink as RRNavLink, useParams } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';

import { FaMapMarkerAlt } from 'react-icons/fa';
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlineLink } from 'react-icons/ai';
import { BsClipboard, BsClipboardCheck } from 'react-icons/bs';

import styled, { css, keyframes } from 'styled-components';

const growAnimation = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
`;

const shrinkAnimation = keyframes`
  from {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
`;

const MarkerIcon = styled(FaMapMarkerAlt)`
  ${({ isSelected, isLastSelected }) => css`
    ${isSelected &&
        css`
        animation: ${growAnimation} 0.5s linear forwards;
      `}

    ${isLastSelected &&
        css`
        animation: ${shrinkAnimation} 0.5s linear forwards;
      `}
  `}
`;


const History = () => {

    const { userId } = useParams();

    const [selected, setSelected] = useState(null)
    const [lastSelected, setLastSelected] = useState(null)
    /*
    const {
        data: settings, isSuccess: settingsLoaded, isFetching: settingsFetching
    } = useGetSettingsQuery({user_id: userId}); //,{refetchOnMountOrArgChange: true,}
    */
    const {
        data: settings, isSuccess: settingsLoaded, isFetching: settingsFetching
    } = useGetProfileQuery(userId != null ? { user_id: userId, data_type: 'settings' } : { data_type: 'settings' }); //,{refetchOnMountOrArgChange: true,}

    const [setSettings, { }] = useSetSettingsMutation();

    const [currentPage, setCurrentPage] = useState(1)
    /*
    const {
        data: history,
        isSuccess: historyLoaded,
        isFetching: historyFetching

    } = useGetHistoryQuery(currentPage - 1); //,{refetchOnMountOrArgChange: true,}
    */
    const {
        data: history,
        isSuccess: historyLoaded,
        isFetching: historyFetching

    } = useGetProfileQuery(userId != null ? { user_id: userId, data_type: `history_${currentPage - 1}` } : { data_type: `history_${currentPage - 1}` }); //,{refetchOnMountOrArgChange: true,}

    const [copied, setCopied] = useState({})
    const [currentImageUrl, setCurrentImageUrl] = useState(null)
    
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [openedImages, setOpenedImages] = useState([]);

    const measuredRef = useCallback((map, bounds) => {
        if (map !== null) {
            map.target.fitBounds(bounds)

        }
    }, []);

    if (historyFetching || settingsFetching) {
        return <div className='w-100 h-100 d-flex justify-content-center align-items-center'> <Spinner size='lg'></Spinner></div>
    }

    else if (historyLoaded && settingsLoaded) return (settings.settings_save_history.value ?
        (history.has_records ? history.records.map((group, i) =>
            <>

                <fieldset class="border rounded-3 p-3">
                    <legend class="float-none w-auto px-3">
                        {JSON.parse(process.env.REACT_APP_MODE_NAMES)[group.type]}
                    </legend>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>
                                    фото
                                </th>
                                <th>
                                    угадано
                                </th>
                                <th>
                                    когда
                                </th>

                            </tr>
                        </thead>
                        <tbody style={{wordBreak: 'break-all'}}>

                            {group.records.map((record, j) =>
                                <>
                                    <tr onMouseOver={() => { if (JSON.stringify([i, j]) !== JSON.stringify(selected)) { setLastSelected(selected); setSelected([i, j]) } }}>

                                        <td>
                                            <a className='me-2' href={`https://www.mapillary.com/app/?pKey=${record.url}&focus=photo`}>

                                                <AiOutlineLink size={20} />
                                            </a>
                                            <p className='genos fs-4 d-inline-block'> {`${record.coords[0]},${record.coords[1]}`}
                                                <CopyToClipboard text={`${record.coords[0]},${record.coords[1]}`}

                                                    onCopy={() => { setCopied(i + '' + j) }} >
                                                    {
                                                        copied === i + '' + j ? <><BsClipboardCheck className='ms-2' size={16} />
                                                            <Alert className='p-2 d-inline-block ms-2 m-0' color="success">
                                                                <span style={{ fontFamily: 'var(--bs-body-font-family)' }} className='fs-6'> скопировано </span>
                                                            </Alert></> : <BsClipboard className='ms-2' size={16} style={{ cursor: 'pointer' }} />
                                                    }
                                                </CopyToClipboard>
                                            </p>


                                        </td>
                                        <td>
                                            {record.guessed ? <AiFillCheckCircle color='green' /> : <AiFillCloseCircle color='red' />}
                                        </td>
                                        <td>
                                            {record.days_ago}
                                        </td>
                                        <td className='text-end'>
                                            {record.file_url ?
                                                <Button
                                                    onClick={() => setOpenedImages(openedImages => [...openedImages, j])}
                                                    color="primary"
                                                    id={`toggler${j}`}
                                                    className='ms-auto'

                                                >
                                                    <FaEye color='white' size={20} />
                                                </Button> : null}
                                        </td>
                                    </tr>
                                    {record.file_url ? 
                                    <tr>
                                        <td colSpan={5}>
                                            <UncontrolledCollapse toggler={`#toggler${j}`}>
                                                {openedImages.includes(j) ?
                                                    <div onClick={() => {
                                                        setCurrentImageUrl(
                                                            `${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}${record.file_url}`
                                                        );
                                                        
                                                        if(!record.is_pano) toggleModal();

                                                    }} style={{ cursor: 'pointer', backgroundImage: `url(${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}${record.file_url})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', overflow: 'hidden', maxHeight: '600px' }} className='ms-auto me-auto w-100 d-flex flex-shrink-1 flex-grow-1 position-relative justify-content-center align-items-center '>
                                                        {!record.is_pano ?
                                                            <img style={{ zIndex: 99, maxHeight: '600px', maxWidth: '100%' }} src={`${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}${record.file_url}`
                                                            } ></img>
                                                            :
                                                            <div style={{ zIndex: 99, width: '100%' }}>
                                                                <Pannellum
                                                                    width="100%"
                                                                  
                                                                    image={`${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}${record.file_url}`
                                                                    }
                                                                    pitch={10}
                                                                    yaw={180}
                                                                    hfov={110}
                                                                    autoLoad
                                                                >

                                                                </Pannellum>
                                                            </div>
                                                        }
                                                        {!record.is_pano ?
                                                            <div style={{ backdropFilter: 'blur(2.5em)' }} className='position-absolute w-100 h-100'>

                                                            </div> : null}

                                                    </div> : null}
                                            </UncontrolledCollapse>
                                        </td>
                                    </tr> : null }

                                </>
                            )}


                        </tbody>
                    </Table>

                </fieldset>
                <div className='w-100' style={{ height: '15em' }}>
                    <MapContainer key={String(currentPage) + JSON.stringify(history.records.indexOf(group))} whenReady={self => measuredRef(self, group.records.map(record => record.coords))} center={[51.505, -0.09]} zoom={6} maxZoom={6} scrollWheelZoom={true}>

                        <TileLayer
                            url={`${!!+process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/django_static/static/tiles/{z}_{x}_{y}.png`}
                        />
                        <FeatureGroup >

                            {group.records.map((record, i) => <Marker icon={L.divIcon({

                                className: "custom-icon", html: ReactDOMServer.renderToString(

                                    <div className={`custom-marker-container`}>

                                        <MarkerIcon
                                            isSelected={JSON.stringify(selected) === JSON.stringify([history.records.indexOf(group), i])}
                                            isLastSelected={JSON.stringify(lastSelected) === JSON.stringify([history.records.indexOf(group), i])}
                                            color={record.guessed ? 'green' : 'red'}
                                            size={42}
                                        />

                                    </div>


                                )
                            })} position={record.coords} />
                            )}
                        </FeatureGroup>
                    </MapContainer>
                </div>

                {settings.settings_save_history.value ?
                    <div className='d-flex pt-3'>
                        <Pagination className='m-auto' >
                            <PaginationItem>
                                <PaginationLink onClick={() => setCurrentPage(1)}
                                    first
                                    href="#"
                                />
                            </PaginationItem>

                            <PaginationItem>
                                <PaginationLink onClick={() => setCurrentPage(currentPage => currentPage > 1 ? currentPage - 1 : currentPage)}
                                    href="#"
                                    previous
                                />
                            </PaginationItem>

                            {currentPage > 1 ?
                                <PaginationItem >
                                    <PaginationLink onClick={() => setCurrentPage(currentPage => currentPage - 1)} href="#">
                                        {currentPage - 1}
                                    </PaginationLink>
                                </PaginationItem>
                                : null
                            }

                            <PaginationItem>
                                <PaginationLink href="#">
                                    <b>{currentPage}</b>
                                </PaginationLink>
                            </PaginationItem>
                            {currentPage < history.page_num ?
                                <PaginationItem onClick={() => setCurrentPage(currentPage => currentPage + 1)}>

                                    <PaginationLink href="#">
                                        {currentPage + 1}
                                    </PaginationLink>
                                </PaginationItem>
                                : null}
                            <PaginationItem onClick={() => setCurrentPage(currentPage => currentPage < history.page_num ? currentPage + 1 : currentPage)}>
                                <PaginationLink
                                    href="#"
                                    next
                                />
                            </PaginationItem>
                            <PaginationItem onClick={() => setCurrentPage(history.page_num)} >
                                <PaginationLink
                                    href="#"
                                    last
                                />
                            </PaginationItem>
                        </Pagination>
                    </div> : null
                }
                <div style={{ zIndex: 1000 }}>
                    <Modal isOpen={modalOpen} fullscreen={true} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}></ModalHeader>
                        <ModalBody className='text-center d-flex'>
                            <div className='w-100 d-flex align-items-center justify-content-center'>
                               
                                    <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={currentImageUrl} />
                                   
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </>

        ) : <div className='m-auto text-center fs-4'><hr></hr>история пока пуста...<hr></hr></div>) :
        <div className='m-auto text-center fs-4'><hr></hr>сохранение истории отключено в настройках <Button onClick={() => setSettings({ settings_save_history: true })} color='primary' outline>включить</Button> <hr></hr></div>
    )
}

export default History