
import { useState, useEffect, useRef } from 'react';
import { useGetGameMetaDataQuery } from '../redux/apiSlice';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TopBar from './TopBar';

import Map from './Map';

import { RiArrowUpDownFill } from 'react-icons/ri';

const Header = () => {

  const { data: {isActive:isActiveGame} = {isActive:undefined} } = useGetGameMetaDataQuery();

  const mapRef = useRef(null);


  const [offset, setOffset] = useState(0);
  const [isResizing, setIsResizing] = useState(false);

  const [stream, setStream] = useState(null);
  const [game, setGame] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('stream') && !location.pathname.includes('streaming_list')) {setStream(true); setGame(false)}
    else if (location.pathname.includes('game')) {setGame(true); setStream(false)}
    else { setStream(false); setGame(false) }
  }, [location]
  )


  const onMouseUp = e => { setIsResizing(false) }
  const onMouseMove = e => {
    let yCoord;
    if (e?.touches) { yCoord = e.touches[0].pageY }
    else { yCoord = e.pageY }

    let bounds = mapRef.current.getBoundingClientRect(); if (isResizing) {
     
      mapRef.current.style.height = ` ${yCoord - offset - bounds.top}px `
    }

  };


  useEffect(() => {
    if (isResizing) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'visible'
  }, [isResizing]

  )

  useEffect(() => {

    window.addEventListener('mouseup', onMouseUp)
    window.addEventListener('touchend', onMouseUp)

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('touchmove', onMouseMove)


    return function cleanup() {
      window.removeEventListener('mouseup', onMouseUp)
      window.addEventListener('touchend', onMouseUp)

      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('touchmove', onMouseMove)
    }
  })

 useEffect(() => {
   
    if ( !game && !stream ) {

      mapRef.current.style.transition = "height 1s ease-in-out";
      mapRef.current.style.height = '0px';
      setTimeout(() => {
        
        mapRef.current.style.transition = "height 0s";
      }, 1000);
    }

    else if ( game || stream ) {

      if (mapRef.current.style.height === '0px') {
        mapRef.current.style.transition = "height 1s ease-in-out";
        mapRef.current.style.height = '200px';
        setTimeout(() => {

          
          mapRef.current.style.transition = "height 0s";

        }, 1000);
      }

    }
  }, [game,stream]);

  const isActiveGameStream = useSelector(state => state.mapStream.isActive)
  
  return (<div className='position-relative'>
    
    
    <TopBar />
 
    <div id='map-resizable-container' style={{ position: 'relative', height: "0px", width: "100%", overflow: 'hidden' }} ref={mapRef}>

      <div style={{ height: "100%", width: "100%" }}>
        {
          <CSSTransition in={ stream ? !isActiveGameStream : !isActiveGame} classNames='fade' timeout={500} unmountOnExit={true} >
            <div className='layer-over-map'></div>
          </CSSTransition>
        }
        
        <Map/>
      </div>
    </div>
    
    <div className='resize-bar d-flex align-items-center justify-content-center'
      onTouchStart={(e) => {
        let bounds = e.target.getBoundingClientRect();
        setOffset(e.touches[0].clientY - bounds.top);
        if ( game || stream ) setIsResizing(true)
      }}
      onMouseDown={(e) => {
        let bounds = e.target.getBoundingClientRect();
        setOffset(e.clientY - bounds.top);
        if ( game || stream ) setIsResizing(true)
      }

      }
      style={{ cursor: 'pointer',  width: "100%", height: "20px", backgroundColor: 'rgb(58, 58, 58)' }} >
      <div className='d-flex align-items-center justify-content-center' style={{ zIndex: 1000, width:'3em',height:'3em', borderRadius:'50%', backgroundColor:'white'}}>
        <RiArrowUpDownFill size={25} className='resize-icon' />
        </div>
    </div>

  </div>

  )

}

export default Header;

