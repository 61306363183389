import { Button, Spinner } from "reactstrap"
import { useGetHintStatusQuery, useStartGameMutation, useGetGameMetaDataQuery } from "../../redux/apiSlice";
import { FcIdea } from "react-icons/fc";
import { FaMapMarkerAlt } from "react-icons/fa";
import { CSSTransition } from "react-transition-group";
import { useState, useEffect } from "react";

import styled, { keyframes } from 'styled-components';
const Hints = ({ onClickAction, hintLoading, isError, isUsed }) => {

  const { data: hintStatus, isSuccess: hintStatusLoaded, isFetching: hintStatusLoading } = useGetHintStatusQuery();
  const { data: { isActive: isActiveGame } = { isActive: undefined } } = useGetGameMetaDataQuery();
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    if (hintStatus) {
      setRemovedList(prevList => {
        // Create a new list by filtering the previous list
        const updatedList = prevList.filter(item => {
          return !hintStatus[item].status || hintStatus[item].used;
        });
        return updatedList;
      });
    }
  }, [hintStatus]);


  useEffect(() => {

    if (hintStatusLoading) {
      setShowMessage(false)
    }

  }, [hintStatusLoading])

  useEffect(() => {

    setShowMessage(true)

  }, [hintLoading, isError, isUsed])

  const [removedList, setRemovedList] = useState([])

  const slideAndReduceHeight = keyframes`
  0% {
    transform: translateX(0);
    height: 40px; 
  }
  50% {
    transform: translateX(100%);
    height: 40px; 
  }
  100% {
    transform: translateX(100%);
    height: 0;
  }
`;

  // Styled component with animation
  const AnimatedDiv = styled.div`

  .remove {
    animation: ${slideAndReduceHeight} 1.5s forwards;
  }

  .removable-button {
    width: 200px;
    background-color: #3498db;
    color: white;
    height: 40px;
    overflow: hidden;
  }
 
`;


  return (
    <div className='p-2 w-100 text-center'>
      <p className='fw-bolder'>ПОДСКАЗКИ</p>

      {hintStatusLoaded &&

        <>


          {
            Object.entries(hintStatus).map(([name, { status, used, cost, verbose_name }]) =>

              !removedList.includes(name) &&
              <AnimatedDiv >
                <div onClick={() => { onClickAction(name) }} onAnimationEnd={() => setRemovedList(removedList => [...removedList, name])} style={{ overflow: 'hidden' }} className={` ${!used && status ? '' : 'remove'}  d-flex align-items-center `}>

                  <Button disabled={!isActiveGame || hintLoading} className='mt-2 rounded-4 text-lowercase' block outline color='primary'>

                    {verbose_name}

                  </Button>
                  <div className="flex-shrink-0 pt-2 ps-2">
                    <span className="fw-bold">{cost}x</span>
                    <div style={{ transform: 'scale(0.8)' }} className='position-relative d-inline-block' >
                      <FaMapMarkerAlt color='#47a0ff' size={35} />
                      <div className='position-absolute top-0 start-50 translate-middle-x'>
                        <FcIdea size={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </AnimatedDiv>



            )
          }
          {showMessage &&
            <>
              {hintLoading && <div style={{ maxWidth: '12em' }} className='w-75 m-auto text-center'><Spinner size='sm'></Spinner> это может занять некоторое время... <hr></hr></div>}
              {isError && <div style={{ maxWidth: '12em' }} className='w-75 m-auto text-center text-danger'> не удалось использовать подсказку <hr></hr></div>}
              {isUsed && <div style={{ maxWidth: '12em' }} className='w-75 m-auto text-center text-success'> подсказка использована <hr></hr></div>}
            </>}
        </>

      }
      <div className='text-center m-2 mt-auto'> не загрузились изображения? <a className='d-block' href='/game'>обновить страницу</a> </div>

    </div>
  )
}

export default Hints