import { Button, Fade, Spinner } from "reactstrap"

import { useGetGameMetaDataQuery, useGetProfileQuery, useStartGameMutation, useStopGameMutation, useSetSettingsMutation } from "../../redux/apiSlice";
import SendButton from "./SendButton";
import ShowAnswerButton from "./ShowAnswerButton";
import { setFetching, setGameFeedback } from '../../redux/mapSlice';
import { useDispatch, useSelector } from "react-redux";
import { GoArrowUp, GoArrowDown } from 'react-icons/go'
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import SkipButton from "./SkipButton";
import styled, { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(0);
    visibility: visible;
  }
  20% {
    transform: scale(0.2);
    opacity: 0.9;
    visibility: visible;
  }
  100% {
    transform: scale(1);
    opacity: 0;
    visibility: visible;
  }
`;

const PulseDiv = styled.div`
  pointer-events: none;
  border-radius: 50%;
  content: '';
  position: absolute;
  left: -5em;
  top: -5em;
  width: 12em;
  height: 12em;
  visibility: hidden;
  background-color: ${(props) => props.color};
  animation: ${(props) => (props.pulsing ? pulseAnimation : null)} 1s ease-in-out infinite;
  animation-delay: ${(props) => props.animationDelay}
`;
const CurrentGame = ({ stream, isSending, icon }) => {


  const { data: { isActive: isActiveGame, mode: currentGameMode } = { isActive: undefined, mode: undefined } } = useGetGameMetaDataQuery();
  const [startGame, { }] = useStartGameMutation();
  const [stopGame, { }] = useStopGameMutation();

  const { data: userSettings } = useGetProfileQuery({data_type:'settings'});
  const { data: userData } = useGetProfileQuery({data_type:'username'});

  const [ setSettings, {} ] = useSetSettingsMutation();

  const [pulsing, setPulsing] = useState(false)

  const gameFeedback = useSelector(state => stream ? state.mapStream.gameFeedback : state.map.gameFeedback)

  useEffect(() => {
   
    if (icon) {

      setPulsing(true)
     
      setTimeout(() => {
        setPulsing(false)
      }, 1000);
    }

  }, [icon])


  const dispatch = useDispatch();
  
  const start = () => {
    switch (currentGameMode) {
      case 'fast': {
        dispatch(setFetching(true));
        startGame()
        break;
      }
      default: {
        startGame()
      }
    }
  }
  return (
    <div className='p-2 w-100 text-center'>
      <p className='fw-bolder '>ТЕКУЩАЯ ИГРА</p>


      <div className='position-relative d-inline-block' >
        {isSending ? <Spinner size='lg'></Spinner> :
          <>

            {icon?.type === 'success' &&
              <>
                <PulseDiv color={'#699f4c'} animationDelay='0.2s' pulsing={pulsing} />
                <PulseDiv color={'#699f4c'} animationDelay='0s' pulsing={pulsing} />
              </>

            }

            {icon?.type === 'fail' &&
              <>
                <PulseDiv color={'#FF5555'} animationDelay='0.2s' pulsing={pulsing} />
                <PulseDiv color={'#FF5555'} animationDelay='0s' pulsing={pulsing} />
              </>

            }

            { icon?.html}

          </> 
        }
      </div>
      <div className='text-center m-2 ms-auto me-auto'>
        { gameFeedback.message}
        {(gameFeedback.points != null) && (gameFeedback.points !== 0) && (<div className="position-relative d-inline-block m-2 fw-bold" > {Math.abs(gameFeedback.points)} <div className="position-absolute bottom-50 start-50" > {gameFeedback.points > 0 ? <GoArrowUp size={15} color="green" /> : <GoArrowDown size={15} color="red" />} </div> </div>)}
      </div>


      {!stream &&
        <>
          <SendButton />

          {currentGameMode !== 'fast' ? <ShowAnswerButton /> : null}
          <SkipButton />
          {!isActiveGame && (userSettings?.settings_auto_restart.value === false || currentGameMode === 'fast') ?
            < Button className="mt-2 rounded-4 text-uppercase" color='primary' block  onClick={start}> начать новую игру </Button> : null
          }
          {isActiveGame && currentGameMode === 'fast' ?
            < Button className="mt-2 rounded-4 text-uppercase" color='secondary' block outline onClick={stopGame}> завершить игру </Button> : null
          }
          { userSettings?.settings_streaming.value ?
            < Button className="mt-2 rounded-4 text-uppercase" color='success' block outline onClick={ () => setSettings({ settings_streaming: false })}> остановить стрим </Button> : 
            < Button className="mt-2 rounded-4 text-uppercase" color='success' block outline onClick={ () => setSettings({ settings_streaming: true })}> вести стрим </Button>
          }
        </>
      }
    </div>
  )
}

export default CurrentGame