import React, { useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {  Form, Input, Button, ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import {  useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProfileQuery } from '../../redux/apiSlice';

const ChatSocket = ({socketUrl}) => {

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const { sendMessage, lastMessage } = useWebSocket(socketUrl);

  const handleMessageSubmit = (event) => {
    event.preventDefault();
    sendMessage(JSON.stringify({ message }));
    setMessage("");
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleNewMessage = (event) => {
    const data = JSON.parse(event.data);
    
    switch(data.type) {
      case 'chat_message': {
        setMessages((messages) => [...messages, { text: data.message, user: data.user }]);
      }; break;
      case 'chat_messages': {
        setMessages(data.messages);
      }
      
    }
    //setMessages((messages) => [...messages, { text: data.message, user: data.user }]);
    
  };

  useEffect(() => {
    if (lastMessage !== null) {
      handleNewMessage(lastMessage);
    }
  }, [lastMessage]);


  return (
    <div className='d-flex flex-column m-2'>
      <div className='w-100' style={{ maxHeight: '20em',overflowY: 'scroll' }}>
        <ListGroup >
          {[...messages].reverse().map((message, index) => (
            <ListGroupItem key={index} className="border-0">
              <div className='d-flex'>
                <div className='fw-bold me-2'>{message.user}</div>
                <div style={{ wordBreak: 'break-word' }}>{message.text}</div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
      <div>
        <Form onSubmit={handleMessageSubmit} className="mt-3">
          <div className="d-flex">
            <Input
              type="text"
              placeholder="введите сообщение..."
              value={message}
              onChange={handleInputChange}
              className="mr-2"
            />
            <Button color="primary" type="submit" className='ms-2'>
              ОК
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};


const Chat = () => {

  const { userId } = useParams();
  const ws_scheme = window.location.protocol == "https:" ? "wss" : "ws";
 
  const {data, isFetching, isSuccess} = useGetProfileQuery({user_id:null});

  if (isSuccess && !isFetching ) return <ChatSocket socketUrl={

    `${ws_scheme}://${!!+process.env.REACT_APP_DEBUG ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/ws/chat/
          ?user_id=${encodeURIComponent( userId != null ? userId : data.user_id )}`
  } />
  

};

export default Chat;