import { Progress } from 'reactstrap'
const Timer = ({fetching, connectionStatus, time}) => {
  return (
    connectionStatus==='Open' && 
    <Progress className='m-2' 
    animated={ fetching }
    value={ !fetching ? time / 15 * 100 : 100 }
  />
  )
}

export default Timer