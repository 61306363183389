import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

export const mapSliceStream = createSlice({
    name: "mapStream",
    initialState: {

        images: [],
        markersPrev: [],
        markers: [],
        marker_history: [],
        circles: [],
        currentImage: 0,
        // use for fast game mode:
        currentGuess: null,
        gameFeedback: { message: null, points: null },
        isActive: false,
        fetching: false,
        sending: false

    },

    reducers: {

        setMetaData: (state, action) => {

            state.isActive = action.payload.isActive
            state.mode = action.payload.mode
            state.image_num = action.payload.image_num;

            /*
            let isActive =  action.payload.isActiveGame
            let isFetching =  action.payload.isFetching

            if (isActive != null) state.isActive = isActive
            if (isFetching != null) state.fetching = isFetching
            */

        },

        setGameFeedback: (state, action) => {

            state.gameFeedback = action.payload

        },

        setCurrentGuess: (state, action) => {

            state.currentGuess = action.payload

        },

        setFetching: (state, action) => {

            state.fetching = action.payload.fetching

        },
        setSending: (state, action) => {

            state.sending = action.payload

        },
        refresh: (state, action) => {
            state.markersPrev = state.markers;
        },

        setImages: (state, action) => {
            state.images = action.payload.images
        },

        setMarkers: (state, action) => {

            state.markersPrev = state.markers;
            state.markers = action.payload.markers;

            if (action.payload.marker_history != null) {
                state.marker_history = action.payload.marker_history; ////
            }
            if (action.payload.circles != null) {
                state.circles = action.payload.circles;
            }
           
        }
        ,

        updateMarker: (state, action) => {

            if (!action.globalState.api.queries['getGameMetaData(undefined)'].data.isActive) return;
           
            state.markersPrev = state.markers;
            state.markers = state.markers.map((marker, id) => {
                if (marker?.right != undefined) return marker
                if (id === action.payload) return { ...marker, badge: state.images[state.currentImage].id, imgIdx: state.currentImage }
                else if (marker.badge === state.images[state.currentImage].id) return { ...marker, badge: null, imgIdx: null }
                else return marker

            })
        },
        setCurrentImage: (state, action) => {

            //const length = action.globalState.api.queries['getGameData(undefined)'].data.images.filter(image => !image.additional).length//= state.mode === 'many' ? 4 : 1 //////////////////////////////
            const idx = action.payload
            
            state.currentImage = idx >= 0 ? idx % state.image_num : state.image_num + idx % state.image_num;

            action.asyncDispatch(refresh())
        },

        nextImage: (state, action) => {

            //const length = action.globalState.api.queries['getGameData(undefined)'].data.images.filter(image => !image.additional).length//= state.mode === 'many' ? 4 : 1 //////////////////////////////

            if (state.image_num > 1) {
                while (state.markers.find(marker => marker.badge === state.currentImage)) {
                    if (state.markers.filter(marker => marker.badge == null).length === 0) break;
                    state.currentImage = (state.currentImage + 1) % state.image_num

                }
            }

        }

    }
})

export default mapSliceStream.reducer;
export const { setMetaData, setMarkers, setImages, updateMarker, setCurrentImage, nextImage, refresh, setFetching, setCurrentGuess, setSending, setGameFeedback } = mapSliceStream.actions