import { useGetGameMetaDataQuery, useGetProfileQuery, useSendGuessMutation } from "../../redux/apiSlice";
import { setCurrentGuess, setSending } from '../../redux/mapSlice';
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Button } from "reactstrap";
const SendButton = () => {

    const navigate = useNavigate();

    const { data: { isActive: isActiveGame, mode: currentGameMode } = { isActive: undefined, mode: undefined } } = useGetGameMetaDataQuery();
    const [sendGuess, { isLoading: isSending }] = useSendGuessMutation({ fixedCacheKey: 'shared-send-guess' });

    const { data: userSettings } = useGetProfileQuery({data_type:'settings'});

    const dispatch = useDispatch();
    const mapState = useSelector(state => state.map)
    
    const send = () => {
        switch (currentGameMode) {
            case 'fast': {
                dispatch(setSending(true))
                dispatch(setCurrentGuess({ 'type': 'guess', 'data': { answer: mapState.markers, show_right_answer: false } }))
                break;
            }
            default: {
                sendGuess({ answer: mapState.markers, show_right_answer: false }).unwrap().catch((err) => err && navigate('/error'))
            }
        }
    }
    return (


        <div style={{ display: ( userSettings?.settings_show_send_button.value || (currentGameMode === 'many') || (currentGameMode === 'photo') ) && (currentGameMode !== 'warmer')  ? 'block' : 'none' }} >
            <Button disabled={ currentGameMode === 'fast' ? isSending || !isActiveGame : mapState.sending || !isActiveGame } className='rounded-4 text-uppercase' block  color='primary' onClick={send}>готово</Button>
        </div>
    )
}

export default SendButton