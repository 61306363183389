

import { useState } from 'react';
import ChooseModeButton from './ChooseModeButton';
import { AiFillCloseCircle } from 'react-icons/ai'
import AuthModal from './AuthModal';

import {

    NavbarBrand,
    Navbar,
    NavbarToggler,

    Offcanvas,
    OffcanvasBody,
 
    Button,
    ButtonGroup
} from 'reactstrap';

import { NavLink as RRNavLink } from 'react-router-dom';

import { useCheckIfLoggedQuery, useLogoutMutation } from '../redux/apiSlice';

import Points from './Points';

import logoImg from './logo_minified.svg'

const TopBar = () => {


    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const { data: isLogged } = useCheckIfLoggedQuery()

    const [logout, { }] = useLogoutMutation()

    const [authModalVisible, setAuthModalVisible] = useState(false);


    
    const Links = ( {vertical,closeMenu} ) => (
      

        <ButtonGroup vertical={vertical}   className="w-100 ">
                                    <Button to="/profile" tag={RRNavLink} color="primary" outline onClick={closeMenu}>
                                        мой профиль
                                    </Button>
                                    <Button to="/modes" tag={RRNavLink} color="primary" outline onClick={closeMenu}>
                                        типы игры
                                    </Button>
                                    <Button to="/streaming_list" tag={RRNavLink} color="primary" outline onClick={closeMenu}>
                                        стримы
                                    </Button>
                                    <Button to="/theme" tag={RRNavLink} color="primary" outline onClick={closeMenu}>
                                        тематический месяц
                                    </Button>
                                    {!isLogged?.isAuthenticated ?
                <Button color="primary" outline onClick={() => {  setAuthModalVisible(!authModalVisible); closeMenu() }}>
                    войти
                </Button>
                :
                <Button color="primary" outline onClick={() => {  logout(); closeMenu() }} href="/" >
                    выйти
                </Button>

            }
                           

                                </ButtonGroup>
   
)

    return (
        <>

            <Navbar className='flex-lg-wrap flex-nowrap' full='true' color='light'>

                <NavbarBrand href="/" style={{ backgroundImage: `url(${logoImg})` }}>

                </NavbarBrand>

                <div className='ms-auto d-none d-lg-flex ms-3 me-3 p-1 flex-grow-1 flex-shrink-0'>

                    <Links vertical={false} />
                    
                </div>

                <div className='ms-auto align-items-center d-flex flex-nowrap'>


                    <ChooseModeButton />
                    
                        <NavbarToggler onClick={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)} className='d-lg-none d-inline' />
                        <Offcanvas
                            
                            style={{ background: 'linear-gradient(0deg, rgb(30, 139, 196, 1) 0%, rgba(73, 162, 255, 1) 100%)' }}
                            isOpen={isMenuOpen}
                            direction="end"
                            toggle={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)}
                        >

                            <OffcanvasBody >
                                <div onClick={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)}  className='position-absolute m-3 top-0 end-0 ' style={{cursor:'pointer'}}><AiFillCloseCircle size={30} color='white' /></div>
                            <Points bg='white' /> 
                              <div className='text-white fs-3 comfortaa text-uppercase text-end'>Меню</div>
                              <Links vertical={true} closeMenu = {() => setIsMenuOpen(false)} />
                                    
                            </OffcanvasBody>
                        </Offcanvas>

                    
                </div>
                <div className='d-none d-lg-block'><Points/> </div>
               
            </Navbar>

            <AuthModal modal={authModalVisible} toggle={() => setAuthModalVisible(!setAuthModalVisible)} />


        </>
    );
}

export default TopBar;