import React, { useEffect, useState } from 'react'
import { useGetProfileQuery } from '../../redux/apiSlice';

import { useDispatch, useSelector } from 'react-redux';
import CarouselComponent from '../CarouselComponent';
import { useGetGameMetaDataQuery, useGetHintStatusQuery } from '../../redux/apiSlice';

import { Socket } from './Socket';
import Check from './check.svg'
import Remove from './remove.svg'
import Hints from './Hints';
import CurrentGame from './CurrentGame';
import MainComponent from './MainComponent';

import 'react-circular-progressbar/dist/styles.css';

import { setImages, setMarkers, setFetching, setCurrentGuess, setSending, setGameFeedback } from '../../redux/mapSlice';


const GameFast = () => {

    const [socketData, setSocketData] = useState(null);

    const [icon, setIcon] = useState(null)

    const { data: { isActive: isActiveGame } = { isActive: undefined, mode: undefined } } = useGetGameMetaDataQuery();

    const { refetch: refetchHintStatus } = useGetHintStatusQuery()
    const {
        refetch: refetchPoints
    } = useGetProfileQuery({data_type:'coins'});

    //const [message, setMessage] = useState('Выберите маркер на карте!');
    //const [points, setPoints] = useState(null)

    const dispatch = useDispatch();
    const isSending = useSelector(state => state.map.sending)

    useEffect(() => {

        if (isActiveGame) {

            dispatch(setFetching(true));
            setSocketData(null);

            dispatch(setImages({ images: [] }));
            dispatch( setGameFeedback( { message: null, points: null } ) )

            setIcon(null);
          
        }
        if (!isActiveGame) {

            dispatch(setFetching(false));

            dispatch(setCurrentGuess(null))
        }

    }, [isActiveGame]);

    useEffect(() => {

        if (socketData) switch (socketData.type) {

            case 'hintResponse': {
                refetchHintStatus()
                break
            };

            case 'data': {
                //console.log(socketData)
                dispatch(setImages(socketData.data));
                dispatch(setMarkers(socketData.data));
                
                setTimeout(() => {

                    dispatch(setFetching(false))
                }, 500);

                break
            };

            case 'response': {


                dispatch(setSending(false));
              
                if (socketData.data.res != null) {

                    dispatch(setFetching(true));
                   
                    if (socketData.data.res === 'guessed') setIcon({ type: 'success', html: <div style={{width:'2em',height:'2em', backgroundSize:'contain', backgroundImage:`url(${Check})`}}></div> });
                    else if (socketData.data.res === 'failed' )setIcon({ type: 'fail', html: <div style={{ width:'2em',height:'2em',backgroundSize:'contain', backgroundImage:`url(${Remove})`}}></div> });
                    else setIcon(null)
                    
                    refetchPoints()

                } else {
                    setIcon(null)
                }

                dispatch( setGameFeedback( { message: socketData.data.message, points: socketData.data.points } ) )
                //setPoints(socketData.data.points)
                //setMessage(socketData.data.message)

                break
            };

        }

    }, [socketData]);

    
    return (

        <MainComponent
            LeftColumn={
                <>
                    {isActiveGame ? <Socket setSocketData={setSocketData} socketData={socketData} /> : null}
                    <CarouselComponent fastMode={true} stream={false} />
                </>
            }
            RightColumn={
                <div className='d-flex flex-lg-column'>
                    <CurrentGame icon={icon} isSending={isSending} />
                    <hr />
                    <Hints onClickAction={(name) => dispatch(setCurrentGuess({ type: 'hint', data: { hint_type: name } }))} />
                </div>
            }
        />
    )

}

export default GameFast
