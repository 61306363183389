

import 'leaflet/dist/leaflet.css';

import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useGetProfileQuery } from '../../redux/apiSlice'
import { Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { useEffect } from 'react';

const Profile = () => {
    const { userId } = useParams();

    const {
        data,
        isSuccess,
        isFetching,
        isError,
        error
    } = useGetProfileQuery({ user_id: userId }); //,{refetchOnMountOrArgChange: true,}

    if (isFetching) {
        return <div className='w-100 h-100 d-flex justify-content-center align-items-center'> <Spinner size='lg'></Spinner></div>
    }

    else if (isSuccess) {

        return (<div className='w-100 w-lg-75 m-auto p-3'>

            <div className='d-flex align-items-center justify-content-between m-3'>
                {userId ? <div className='fs-3 comfortaa'>ВЫ ПРОСМАТРИВАЕТЕ ПРОФИЛЬ ПОЛЬЗОВАТЕЛЯ <b>{data.username}</b> </div> :
                    <div className='fs-3 comfortaa'><b>{data.username}</b> | МОЙ ПРОФИЛЬ</div>
                }
                {userId ?
                <div className='fs-5'>у <b>{data.username}</b> <b>{data.points}</b> и <b>{data.hint_points}</b> </div>
                : 
                <div className='fs-5'>у вас <b>{data.points}</b> и <b>{data.hint_points}</b> </div>
    }
            </div>
            <Nav className='text-uppercase m-3' tabs>
                <NavItem>
                    <NavLink
                        tag={RRNavLink}
                        to={userId ? `/profile/${userId}/achievements` : `/profile/achievements`}
                    >
                        достижения
                    </NavLink>
                </NavItem>
                {userId == null ?
                    <NavItem>
                        <NavLink tag={RRNavLink} to={userId ? `/profile/${userId}/settings` : '/profile/settings'}>
                            настройки
                        </NavLink>
                    </NavItem> : null
                }
                <NavItem>
                    <NavLink tag={RRNavLink} to={userId ? `/profile/${userId}/history` : '/profile/history'}>
                        история
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink tag={RRNavLink} to={userId ? `/profile/${userId}/liked` : '/profile/liked'}>
                        понравившееся
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink tag={RRNavLink} to={userId ? `/profile/${userId}/stats` : '/profile/stats'}>
                        статистика
                    </NavLink>
                </NavItem>
            </Nav>
            <Outlet />



        </div>)


    } else if (isError) {

        return <div className='w-100 w-lg-75 m-auto p-3'><h3>Этот профиль не является открытым или не существует</h3></div>
    }

}

export default Profile